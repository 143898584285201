// Code adapted from https://github.com/techomoro/ReactMultiPageWebsite

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Minting,
  Terms
} from "./components";

ReactDOM.render(
  <>
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/minting" element={<Minting />} />
        <Route path="/termsofuse" element={<Terms />} />
      </Routes>
      <Footer />
    </Router>
  </>,

  document.getElementById("root")
);
