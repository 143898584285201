import React, { useState } from 'react';
import { useEffect } from 'react';
import foundation1 from '../images/foundation1.jpg'
import tinkgenesiscollection from '../images/foundationpiecestightnotitles.png'

function Home() {
  const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
      const handler = (e) => setMatches(e.matches);
      mediaMatch.addListener(handler);
      return () => mediaMatch.removeListener(handler);
    });
    return matches;
  };

  const isMobile = useMediaQuery("(max-width: 480px)");
  return (
    <div className="home">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="text-center">
            <h1 className="font-weight-light">Cascade Genesis</h1>
            {isMobile ? <p>The first piece of our Cascade collection, auctioned off on foundation.</p> : <p>The first seven pieces of our Cascade collection, auctioned off on foundation.</p>}
            {isMobile ? <img src={foundation1} width="350" height="525" text-align="center"></img> : <img src={tinkgenesiscollection} width="1219" height="812" text-align="center"></img>}
            <p style={{marginTop: '10px'}}>
              We are a team of three engineers with a passion for building projects at the intersection of science and art. TinkRNG represents the parent company that we have created with the goal of using our understanding of math and molecular biology to highlight some of the incredible and often hidden patterns of life forged from billions of years of evolution. 
            </p>
            <p>
              Cascade by TinkRNG is our first project in which we aim to portray patterns in the human proteome through the creation of a novel, biologically inspired generative art algorithm. Each piece in our collection represents a unique protein, with properties derived from information including the protein’s amino acid sequence, the cellular compartment where the protein localizes to, and the primary biological process that the protein is involved in. 
            </p>
            <p>
              To learn more about our process, motivations, inspiration, and future plans please visit our Medium page where we publish articles on this and more! 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
