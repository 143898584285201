import React, { useState } from 'react';
import contract from '../abis/Cascade.json';
import { ethers } from 'ethers';
import { useEffect } from 'react';
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import './App.css'
const keccak256 = require('keccak256');
const { MerkleTree } = require('merkletreejs');

function Minting() {
  //CHECK IF THESE ARE CORRECT BEFORE DEPLOYMENT
  const contractAddress = "0x863f7aBaf3D279D301A1D5C6E1F321E2cD2b428D";
  const mintPrice = 0.1;
  const correctNetworkID = 1; // 1=Mainnet, 4=Rinkeby

  const abi = contract.abi;
  const [currentAccount, setCurrentAccount] = useState(null);
  const [contractState, setContractState] = useState(-1);
  const [numberOfMints, setNumberOfMints] = useState(6);
  const [errorMessage, setErrorMessage] = useState(null);
  const [mintMessage, setMintMessage] = useState(null);
  const [correctNetwork, setCorrectNetwork] = useState(false);
  const [totalSupply, setTotalSupply] = useState();

  //*******************************************************************************
  // Mobile Functions
  const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
      const handler = (e) => setMatches(e.matches);
      mediaMatch.addListener(handler);
      return () => mediaMatch.removeListener(handler);
    });
    return matches;
  };

  //*******************************************************************************
  // Wallet Functions
  const checkWalletIsConnected = async () => { 
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have metamask installed!");
      return;
    } else {
      console.log("Wallet exists!");
    }

    const accounts = await ethereum.request({method: 'eth_accounts'});

    if(accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }
  }

  const checkContractState = async () => { 
    try {
      checkNetworkState();
      const { ethereum } = window;
      if(ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        const contractstate = await nftContract.state();
        setContractState(contractstate);
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const connectWalletHandler = async () => { 
    const { ethereum } = window;

    if(!ethereum) {
      setErrorMessage("Please install metamask!");
    }

    try{
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(contractAddress, abi, signer);
      setCurrentAccount(accounts[0]);
    } catch(err) {
      console.log(err);
    }
  }

  const checkNetworkState = async () => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const { chainId } = await provider.getNetwork();
        if(chainId!=correctNetworkID) {
          setCorrectNetwork(false);
          setErrorMessage("Not on the right network. Please check if you are on the right network and then refresh page.");
        } else {
          setCorrectNetwork(true);
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(err);
    }
  }

  //*******************************************************************************
  // Minting Functions
  const mintNftHandler_Free = async () => { 
    try {
      const { ethereum } = window;

      if(ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Initialize payment");
        const gasEstimate = await nftContract.estimateGas.mintFree();
        const safeGasLimit = Math.floor(gasEstimate.toNumber() * 1.5);
        let nftTxn = await nftContract.mintFree({ gasLimit: safeGasLimit });

        try {
          setMintMessage("Minting... please wait");
          await nftTxn.wait();

          setMintMessage(<span>Minted, see transaction: <a href={`https://etherscan.io/tx/${nftTxn.hash}`} target="_blank">https://etherscan.io/tx/{nftTxn.hash}</a></span>);
        } catch (err) {
          setErrorMessage("Not a team member");
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const mintNftHandler_Foundation = async () => { 
    try {
      const { ethereum } = window;

      if(ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Initialize payment");
        const cost = numberOfMints * mintPrice - mintPrice;
        const gasEstimate = await nftContract.estimateGas.mintFoundation(numberOfMints, { value: ethers.utils.parseEther(cost+"")});
        const safeGasLimit = Math.floor(gasEstimate.toNumber() * 1.5);
        let nftTxn = await nftContract.mintFoundation(numberOfMints, { value: ethers.utils.parseEther(cost+""), gasLimit: safeGasLimit });

        try {
          setMintMessage("Minting... please wait");
          await nftTxn.wait();

          setMintMessage(<span>Minted, see transaction: <a href={`https://etherscan.io/tx/${nftTxn.hash}`} target="_blank">https://etherscan.io/tx/{nftTxn.hash}</a></span>);
        } catch (err) {
          setErrorMessage("Not a foundation member");
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const mintNftHandler_Whitelist = async () => { 
    try {
      const { ethereum } = window;

      if(ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);
        const whitelistaddress = ["0xa0Ce01cf9a08aFf84F0b4d60818b986425aA2815",
          "0x537e8C201f3b600FC710c61345152837D48F7E13",
          "0x227C59f7dA535d40f2260463F02755336EEAe8B3",
          "0x783dcacc3e603d8fc3c6f0405fdd1a088d65823a",
          "0x35f80420bbdb358b6bf274038aed03b49235e9fc",
          "0xce5449192a61ee6873a4cf051cf8c467ee34f8a3",
          "0xfeD27eA960D1c72D2b2d80C3bf358fE7668d7d6f",
          "0x7037d2b9fdb4df5ca337430d4e95eae8bb4902cc",
          "0x7CE779604DbE0A293cBec245459Abe4e0Fa8eC61",
          "0xDffC60457ac6F6Af63cC646B3f7c2241532EDa27",
          "0xb84934e78ee3f5c75deb9d5e22e2442fdb163c29",
          "0x67dfc0aeefa473e48ad5cb0ed82061c028307f03",
          "0xef61d847e3e1c50c79825dd4544a9cbeafd312a0",
          "0xe0a68cae437373160f1854e411cce05841e1bbaf",
          "0x53F88a316dB21C41a16AF1386a4fdFf412D79E75",
          "0xb81AF1abe8d98f5139193bFB54c41E9B27bA15c5",
          "0xE5669BF0037C495F0A5150ADa6209e4A5eC5dd7C",
          "0xf13c8F4712464C925D7aF2498CFE9c0555Ab38e2",
          "0x8d4588ff9ee5211efb8ad003e8bb2b5a96a7147b",
          "0x7586ce58a84ed627ee3bf5ae9cdba4d4f0d5378d",
          "0x86e3e86b19af90d3f2a87c588e052fb68083152c",
          "0x3bcea9af6146c4ddc6de1d3bf2f5db97fab3e45f",
          "0xEf0C6D3868657ce880546BAE225FC04211D40f19",
          "0x1c65841eda71e91b0dc43dd17bd5aa52b03ee364",
          "0x7858ff3f6b02d497cb9848d68e8f9e58714678f0",
          "0xb86d1bbb39cdea09118962ffe1566150baa18b0e",
          "0xbbe4796f5949bef4a658e86f2c974033b90f1b8d",
          "0xBc1B4740851a8c23E4ab339754E4232d907F4B3b",
          "0x0831f84e5f625e9838c16ee309e2320b9225dd3e",
          "0x0a8da356dc80da3a114d63e752cb42923b124019",
          "0x30ff2ec4fd4cd5a6f7174c6920adeda9bd4b88d7",
          "0xa1CC6E65d4E5d8266e6f3e706685ecE392383100",
          "0xfc76ac80e27178f1f8f9f7ee6669e40dcc8733a5",
          "0x0c2c142bbb28b6dfaa8576fcc2c74cb5bd7d3f70",
          "0x495413828ffa9f448a41ad330bee078ff619540d",
          "0x34865c89bc2345d513f008b9040333b5d9705a09",
          "0xa7d4083a17b20f106688c4c20778e40fbb351ae9",
          "0x987deb5a31c239deddb1a40165ad39c1c449962e",
          "0x97bb12e8427e6fdc7881927db0b0da14445327bb",
          "0x3632579135e3ad291f6ebff509443be8a38b8912",
          "0xaefbc976e720ebb32f5176903fc1dbd46e6e6b74",
          "0xebc46798f5f246f6d6e132c2fec67c70cd9bf2b7",
          "0x699E3f4a8570DCbcF6e9686A1c4C13f4431bE78F",
          "0x254dd957e31ccdeab0b28ca67550ef998b74c540",
          "0xd9375D9958E8E0cebBf85b4A42C958feC45C04A7",
          "0xf70b1A1526385e1BFC365DAa2e24F5FB798563E2",
          "0xC9C0c05F13464e7F5a3Ee78874BF8E05AE352dba",
          "0x399b672b0b7a88eb0cde1b4592b5e7afada2cc05",
          "0x1fabfd128d498fef7da0190ef416a502375bf59a",
          "0x827d12Ee92C61FcC8DE4B90Fd2457264d6E1f36E",
          "0xee6d9d398999ce325ab2132ecfeee475c1f755d8",
          "0xdc4b40bdc5f03730ca9cd247cd49b851ee40aa6f",
          "0x76c06179d404a0ebc506f1370798f60cb0b3a3b5",
          "0xea50c4434c06d0c0390c511274d1bcbc36c10a7d",
          "0x56546c108ed201ca0e4a84e8f9189d6b8357da56",
          "0xe1776419963bd419d5cfb5e00faa193a32c6be6b",
          "0x85921e057223f5b53db397f4e06c9c73101dfe1a",
          "0x17d19da0dacc522ac86e55db724cc7035e2355d9",
          "0x7858ff3f6B02d497Cb9848d68E8F9E58714678f0",
          "0x1a56f562e65007fa3638d9e960a63c9df6a9a6e2",
          "0x0286a22f655f84c36ff6c80eb566a5a4a8f07541",
          "0x8c0ed0e12819f906b2f63fc28d865c695d1e6a1d",
          "0x2a03b0daa35ebcf66979587c08d2af7e8c92a42d",
          "0x3a0a8ecd310c23e909f7ca96e0b7ec42d2c4a957",
          "0x49c43d90e7b062f26cb5ce4d923bbf03ee5cbc92",
          "0xd95568957e1e37a88434d384dc66bfb906ac1ac0",
          "0x5AF1886823a635B66c234989EF79dbEA4022019B",
          "0x8eeccc9a0aac4e6e91eeb05846c3aff4a6bcb454",
          "0xaf32d6cc6d10f63c069b272d31dcc6ced03901cc",
          "0xe5adeb61e9d932141bfd95415d1ed542dcea2245",
          "0xc05444251077c989b15d5460490538c983277163",
          "0x16091344b32cfddb60b9a45032d5ed495c59a52e",
          "0x0d867Fcb1B84Cfb9Ca216632a118e41cE70FC154",
          "0xa147a4e326a24a449c4e96ab284eaa5585483f29",
          "0x3b39c1e0c278457e0e27704b6b8f4a554f2e2bcf",
          "0xc66c86d0a4da3df23ce832c52e15e131190eb8c5",
          "0x1ac41f4721e7f9636e0445cc000450919178ff5c",
          "0x8848abcb98a5c395d0a0de7a0160a9cefc444da9",
          "0x161014cbb1b4492d7dd965bf5be6a4d0b9d859a4",
          "0xCC223eC47BeF2c2E981e38251A2B1FF146C82276",
          "0xeE745E790781BB6E30b1B4a14C6C18A1fE2380FF",
          "0x03ee9d382146565ae7ca4974f42bdb044e27d781",
          "0xeb66a96f4236d165a9e32c26e2886814d36b61c2",
          "0x83448a9cdcb456bb58577c141cd77e311244a156",
          "0xf530cb2cc7e42c0abe288668c41962c6261c44b9",
          "0xe2868b6115019c4bd31d358a6a1ef58da6d0b027",
          "0x5dc70a9b884f78ee030a8c6ad3b3b7dc10bbe7f4",
          "0x98510c5573e7765307cccfdd27b99bb9e28bb415",
          "0xa94f10d20793d54e7494d650af58ea72f0cb5c38",
          "0x5a934dd1967c9bb3a6ff5a329de373a9db920607",
          "0xb5b8f8d1091967ef759a991005863bd0de581fbe",
          "0x821510460bbdbad6df5d495268441ecae4e0d158",
          "0x53f1b3d8990472d1adcd7b76348e5508db17cea0",
          "0xe7fe672aac0ac7f452e8cffb2774e1bbef7cc97d",
          "0x69537c400bc2e942230d5f16fbc42a0a6755fbf2",
          "0x0908b6b35cfd907ef2d9a54d53d2228817fc9585",
          "0x5318b503eae25c6aa7896c7bb3f285646f4ea7ff",
          "0xb8d8d919244f8015ca3c61cd7211386a477fce53",
          "0x612952a8d811b3cd5626ebc748d5eb835fcf724b",
          "0xcff3dd6eb4c3604c9c178ce147353940b7401c07",
          "0x3c13af58ffa70f7d20556d9f9bdb173a3872a17b",
          "0xf21235C8BEE6ff5C5D5124822A9D3767907b7c53",
          "0x407426f1429b6adef4d3f4add2bfd7093dc53b25",
          "0x9516a4e7abed84bf3e22acca0993bdf843e4d0db",
          "0xa3f61be2d755c710f9202dff1e778df098881bab",
          "0x6dE858D04b89387d40Eea8fC762Fe6289396040a",
          "0xf490f58567fabfc2aee47ffa3a87be29590df3b3",
          "0x69f00d70f38624425f0269c87951ca67aa300d35",
          "0x02c48d75b4e6ca45e254ec957d6a99c319ca6915",
          "0x6312327A69Aebd7a7BCcFC82C3566F4B8Cc963c0",
          "0x2ac6f6702f5d685a69258283db04b8bf8494f58c",
          "0x51ff6b96b6e13dfef1cb66c98d9df0bce26327c2",
          "0x446433dff2671b52f3a9ee58fc6776fcb4ad1175",
          "0x64786a62ad601a9830ffb48b6b0085b9e8adefd2",
          "0xe2cdec5f33f08c0e14574b53e7be70338e5e8324",
          "0xb3957e64e48eb4f3e7ea1360a0614d7c6294f5ec",
          "0xf8d68e38b6b380ddcb70b2bee3d1f57d71724eac",
          "0x40e8b81F014a6ceEAE5c73198D25Bf918Fa9670E",
          "0xec4c669e31aae57e4cf5690aee4a80b4eac66722",
          "0xee42cf807adf281877d1dfbfc7726bc9bc00c381",
          "0x8546f08c578b6c64af9ad1d3bec172f6787b5e34",
          "0x63b1b55d056d84c2f252822c1646765c513beda7",
          "0x9ea6e1657820568ab62c9db57ff6e9502a8b87f8",
          "0x30e69c65230d4a93602d0859af78dfc56306cb49",
          "0x54793c8f80f642ed4e200177bf845c61915ebc55",
          "0x0a9c52dcce024dd72aafeb43c1c253b93ab6ee2c",
          "0x171f8fd240b3a9571697acc6054fed2e02180b4c",
          "0x6bc5782a69f43b16564adc87b4b4f5d4d6ac0eaf",
          "0x2457b7af0082ae0e04f164d8f0430300e43f2c8e",
          "0x6eeC61BF05004AE7156871258fb95D261c70c36F",
          "0x09cdd4b0a70d9f92fa47d17c4a58e81430a64c51",
          "0x6a7cc5e205bce058454e1576419f3dab1ce21637",
          "0xa0f33434e8c9fdebc6e459e1b4c6fa21a209fc8b",
          "0x7b13fe6b4a53edd825d4c64773933a3354a54c36",
          "0x9e689a246749fd4da947bb3af886b120d8820a51",
          "0x2cf1e7137efae14caa26b9bd60cf16fd52d5157e",
          "0xe2a323110f58ee47cd6ccdfe303ac75aa1748cb4",
          "0xbb4b8BA39A465C8346b131e9e37079100cEa2FBE",
          "0xf3d7f04be645adbdde46b54f3f760cdf8f7416a5",
          "0xb8d1047861979e496ead3c37dd0370d122b095d2",
          "0x970b52Bf8964934E721f655325cc946e4901bE6b",
          "0x558682feaacde23a57aa0dd22ba4389e95f1cdc2",
          "0x6AD5ffcAC8FdB4fa16C646fb51Df778a3e8E2bb2",
          "0x827d12Ee92C61FcC8DE4B90Fd2457264d6E1f36E",
          "0x9206DC24188b1D7738480335cb792FBe590a95bc",
          "0xB8a004cDc2DEd3a6FdA11bB31Be9e415818383a6",
          "0xe88a0e2c7daa90237b10ff411f2cbd51112e0ae2",
          "0x892eaf46a9C1CB75c3F230420DCbEE6320c26932",
          "0x4d46fb7c88f521b747e377b9ea466e0cf6f32f66",
          "0xed6e311b95b844be1cb5c1e8aea5aad6d70d6b19",
          "0x573f29879312a97c73fe6c29d90924104f0959cb",
          "0x46D7B9f50cD4A815Ab60C2000C7E89aE1d76b913",
          "0xb24538f4efd88ff3f9a48585632085a7a772967d",
          "0x5DA8A510f468aD22b83e8f5BDf191c961FDF6B3b",
          "0x159Bda922AC9c697107e9c942191b13b75A619be",
          "0x027933447f36b8E287A9d67593a8A06ac7d5B7c0",
          "0x1d8f674Ac2114a8Ab4f0C8eB96066490063C0403",
          "0xA922112E468E804b91325A7ca2aB4418834D85aa",
          "0xbaaba861464f25f52c2ee10cc3ac024f4f77812a",
          "0x12de02291711ae1e1d526653b19a8e98dff7782b",
          "0x4433787ed2a8d91133527c316790681aa3db267f",
          "0x9c3028e9daf16350c06cccf41c504c86f1a707a3",
          "0xf566b5046eaf3ce6c0bbe7e45249838b2d1a290d",
          "0x1cdc13514c0771337bb3f000a7b431a8b43bff13",
          "0x554c8665710bd51b777892493684b49baed0c952",
          "0x8de18fad897349716bd6572304abf2071644ecc4",
          "0x97341010d9df19727f458128639ddd81f2b6e5de",
          "0x46d410b7fbaf1a2d43b48a07c15856ad258120fa",
          "0xaa2e61bd52d62cccc988e3dd1e38fc10c25741e5",
          "0x7cd4fe4adcd9de1a1c1256d87840bc808f87fa57",
          "0x863db0752ae039e4b08d52e429a989d277705054",
          "0x7c99baa8f3e767871019b26b319bfcc85a708602",
          "0xfb82bf3c2231d59785da6d34019b7bb6316e0655",
          "0xaf2e919b59734b8d99f6bc1f62dc63d6519d14bc",
          "0x2a80e679389a3de24df93a8f511d04130f24f591",
          "0xb7009501564bd180060ce51bcdac5c3e752c1a6e",
          "0x59c273baad288c05e1c576085669b81e2b08bd5c",
          "0xd4Bc9B4cBC14Ec64256677aE2E1Cdef1116C9AE8",
          "0x92b610912befe75d7de657eeaff2c2eaaa2a3b63",
          "0x6da51449289e61876d64fb1c5650faf6f0780264",
          "0x60f7d7c6af81443ac392cb0df49cbff22eaa52e8",
          "0xcfa66e92f4070a6d80cd45f4738afa5ab6e62519",
          "0x39d0b5fa46c9b13416bb1c90e1c620560f270f0c",
          "0x9c48c15556719b287ef1286f6375fef24ae4d666",
          "0x1dd915aa6d634f67b4356f5a9c25fe7035966371",
          "0x7e4a82326dcb5f40851dcf67b145a3ee68fb1d19",
          "0xba446292d853ef72a093edf5568defc6ca2f3d4b",
          "0xA2247682AE13015990e26699a4481edcF88af1C7",
          "0xe9e79d9f6565d5da2f4e80245a3c9ae0193a28ef",
          "0xb221b68c54c0798aaaf5d8fd3a7d070d32bb57e0",
          "0x60377ec355857c2d06d1ce28555f624257344b0d",
          "0x997a4f3adf0900d8c977fe9ec6b00df90dfccf3b",
          "0x689671361d07525d0c9712cbcd2ab5b93cc288f7",
          "0xB9B039DDa07eaE0DFD99379148908Fb40553C4A1",
          "0x17c81b8b0332b5347bc1f16a133b75d2efee568f",
          "0xacac50316130dbbfd4bf277141d154367a21c89d",
          "0x399f361e5e1a2cf3429994a14835d87a0687ba1d",
          "0x890Ba75982f9dE172021e94A1029A0adC9988481",
          "0x483bb2f47a5aa9b01d8a73c2bc41672adbcb6dd7",
          "0x6f6903dc86974f25c6d135387ac9de338cabf2b5",
          "0xdff32221db532b049b326635d1a4b318ea1ca42a",
          "0xaE5a8e190CE39d9304580d8eCcb9d597df6d9915",
          "0xEc4EE55488eDA04f6e02cdbBb745F50dD612d937",
          "0x85Bb21414e7E87856C4F4E074f5122839ACF19e5",
          "0x8d77417424751e234bed34e3fa632520064af776",
          "0xfddd591caa13f8524165795d1e518e182394cc11",
          "0x640ee0b7ff0722abAfE0e4ec9B23644D499Af173",
          "0xa6ac548e0d1455df44112f8bf8abb9f551017c09",
          "0xbc3e746c622a4b8b3f70fcf43c0ff0d4c66452fe",
          "0xf279d80a97c049641793534b1ac9df982ac677bc",
          "0x0dd777b73ca8ede82e97e1174ba5c97a09d31975",
          "0x47FE44e2A340a788F5faDd1ba91beF585f57A2CC",
          "0xe958fee31899f94ca7f13961c8385564fd1516e7",
          "0x0bce703ca761171101479b262008105190a8cb58",
          "0x397d7ee5ba6d4beec60a98b03bd2e31706180ebb",
          "0x17e31bf839acb700e0f584797574a2c1fde46d0b",
          "0x3e0f64e5305d273df3640b78fc65570e65d9c6ee",
          "0x9ffd8e89cfb99c2c0cd1665280d846e51d8dd8be",
          "0x9a469fe1dbadc5afc66de1437af991722c54f312",
          "0x27dd8740ee48f7b90eb3a07b22f2afdf73f8bab6",
          "0x96f4d8c81b864f58135f6ffa848e5b7c7e5f7bf3",
          "0x90ce1770f872040fcc086d2d7c906019b7107d55",
          "0x69063ab63866e3227c616908a090163af1b3e900",
          "0xe2f0c8df2a5ee0ddc899e547284e8c58ed72088f",
          "0xbcef951c175ae6a1fa73ea449eafed42aa9e52d6",
          "0xb2e83af10ad293ceb9d7e9c657dd0d0e6eb82306",
          "0xfc45452230ce9f0b9acd3a3f78e81928914a40fc",
          "0x0f3c5e934effdc85df5de68e0f96039dee07d4bb",
          "0x6ff6abdf785333ff340c15a5777bf6372c12184c",
          "0x98c68168474c7efe22828eab331ce98655a8ecc9",
          "0x280b5537cbe49beaa74d4073dcc9786c6115ca2c",
          "0x01c2df727013d181b4e6e0ec16735433fd47afe8",
          "0x757707833a4f3589e64eff93ee296739e231fa6b",
          "0x5a672bfeb1a738004bef9e58c1a833acdae8300e",
          "0x0b74df5bf962f95059a27bfbe24811ed7529b86d",
          "0x712e6bba75b1c1415018f53c455e20b9a5b8d4c9",
          "0x52b5394afb87f2a9cdf1a2cef1ac9f3b1b9205cb",
          "0x8d2fa616807cc41e7f9c66433af94572334a4f89",
          "0x088fB8EA146DC79Bb3Add3B7FC67874bb196df34",
          "0x4d5e25d2da612ea938f114c365195eeb01cc3807",
          "0x97c19f6faffec1f1704def5a01c5f571608406de",
          "0x8b9085dfa94f33152f9196f61d36fe94ddd78642",
          "0x7c4c9c8ba895f092c4529b16811dad8d20c437e8",
          "0xe587023e8a7a865569261d7c6ce024f7241d67bc",
          "0xcc93215f0dce36f4e33e4911e68c7179ad18e8bf",
          "0xe75fb7c161a890baddd835c0f00225568cb085ce",
          "0x625d06b17e1d2e3893f8fe7c9eaf87324db72922",
          "0xc7875567208d9e7cf404eeacf4271eca0e55f379",
          "0x0ad14d58c097b0e60170600371e59171c7b1d9df",
          "0xb05d71dbbe62457dce73d83a94987c416f470ccb",
          "0xc89992129135032fca19944211cbf28e63b29e40",
          "0x66fE99CfBaC12f974Eac44aeB26c549D823D1946",
          "0x63b9d29157ed949f4fc601c8c07385c8b6a37326",
          "0x41603e18ff75bb1bdea50c698c9c76c8e81ed8d6",
          "0x22e73da8b02d03415f9233d38682e8328bf64b4c",
          "0xe1e2e153e785118fa7b50e93e6b8cf887f025bc7",
          "0xf7D1E9F18724144287a5c6348854E8a99B606836",
          "0xb397aa1bb01493cfb4f866ada3079e16173f99f3",
          "0xe59260FC51918CA151c4006D851F5F4F96A061B1",
          "0x70d8e0feed34308e4f382823fa3241098f2d4c13",
          "0x7f4a465212aaadac5fa6b437b80e321e70d0e8a5",
          "0x15f865b36b03d582335036b5378864c7dc2e1b78",
          "0x839D7aAD20B0605b4583d790E9927dd31C44D43F",
          "0x9435ebd1a2d75df7d1969ead01959d09a39ad4ee",
          "0x98877c7cf7d7467b424e5558e78feceaf1fc673e",
          "0x3a560cda20b3080509fc0c2a4fd9a14cff5eab30",
          "0xda20b5de72b1e616a653dcf7f1c6eb82863d2c2a",
          "0xddff178979d01f4d77e0024c4163acf836d13842",
          "0x466bba1ce5a7cf5049774b6d601179536b0f47ef",
          "0x871cc24ea2cf86c9c5703ea42176373049a6893e",
          "0x1510c0d3a7a2d64fd77bad535da825f7c5dbf229",
          "0x6c9aae4ece062f737ecb49e6544ab8372f9ca6d0",
          "0xf906dd585585eaee00282107844126d71cfc2019",
          "0x769f26f62b3e9f8de810ed8adbdeeaeb1168b33c",
          "0x564341719f3331250721a0a3227f2265b621b2f2",
          "0x641c2fef13fb417db01ef955a54904a6400f8b07",
          "0xe1e618b61536827570718ee08bea33859b72e8ef",
          "0x90ffbec3c7f0c2b16e68d132246689c38bb5e302",
          "0x49da5aab9d0d3110aa66f3794ccfebe79fb8c322",
          "0x729baff83af8b88bce8eaa784cde3b88ec6af126",
          "0xf15784b9827beb2c9ede0aeee8335ff9dd51cd63",
          "0xe02eeb49fd2f6ed86491850f99f0382527e370fd",
          "0xa07795db4c7cb9fdc114cd7fd8365558f2147d73",
          "0x9bcf50d39d15b15623e3a57628e3cead75890207",
          "0xfc7d85e5e6f755007ecb595cf3815371586817c3",
          "0x444fa4a9d2f43885518ee987e845b10737fb5663",
          "0x077525C12690548b45C29c93b6F00Dc3cBF8EB37",
          "0x38902422940350e8ff7a037754dee3a998c4f208",
          "0x541bd26c66d18ff4a770044db5b9397ba83cd139",
          "0x8d1db0c2fce84a914c7d9bc291980d79d0d8e8c3",
          "0x1fd552a7131a9c08213397697b3afd40e496dc10",
          "0xecf0b63d25825861ee9bd00e89f31bc2e05c514b",
          "0xae7aa103dc1e47c771d0e32d26b50243e7c87052",
          "0xa7ae4300f368937de48ad044338ab9ba8ed01950",
          "0x80d232a48a2d1ece21eae7657ca1e5c2dc62a281",
          "0x5198b9cafaa588870d10a127f1e249b18c0fa2bd",
          "0x2f1390ec03a03e57a1406bdef57c9cf487f62b78",
          "0x0b59e3e73dbe453e973a876d34397c0cb759daef",
          "0x04b75527df1da2577a2682333da6432882b7d7f1",
          "0x7a1424fa67f64c8aacfface68751b4b86027a7d7",
          "0x70c7adbfcac62b465d80d24ff93fd67165444667",
          "0x833c270d36154bba599766bd194adad0255b0e19",
          "0x4904325942c45e7d64c92aa1c734976e1d4ae069",
          "0x37e4aad8d5c596f3e8f535153d80b1f54306bad6",
          "0x9d009c5a668d53858875d3d6c3150bf1b1f07ffe",
          "0x5f383705879fdc3e4f7b392d0a1b088af963487b",
          "0xfc84f9ce5a0ba010344d690f849988fa033031a5",
          "0xaebd74660a350879ffd6a2bd72a1cf22b9d5e686",
          "0xbc0498df8370c64ef5ce287af65d6d6c4d9a033a",
          "0xd4bc1e92b01745c779590571e118b518c9bb2e85",
          "0xbed4dbd1368c6083b6ff7001cd5be006e10fe819",
          "0x8215468107d95a81d67b6449840dc033a655f861",
          "0x6e94e6a71708cd1d3686101a2d3001896b98597e",
          "0x6f2fc153686771941137110fd39ff4f712e0c41c",
          "0xbe1dd3b160022401341034bda5f806f25f5d84fe",
          "0x1f3b00363a447c88a255ea329c3e8e96c8872196",
          "0xf83abc519e046c5391d219fabf1a3c87dd5924d3",
          "0x94c0a3dff04a113e221e80c16acea1f39a6edd6c",
          "0xc783d620b2793e146993b9e783cfea85a37c41b3",
          "0xac175ee89e1ff80d8b28c8618a0f1553a597fba7",
          "0xfb22382833899d2f56efb7f90d8301d55faf2f84",
          "0xa54837c8f04384b89d9cc90ae368dfc51b77e2f4",
          "0x1b9fbead09468a48eb10856ba0eeb1649c6ec4c9",
          "0x31f69e7f8e8ccc4d6ebbc4816ac26e25cef477b8",
          "0xbfef8866db2e9298a90d72c6baf9d7f2bd835930",
          "0xb04a0f6c86a20d0155907102e97824d915815feb",
          "0x2809e0e1188ad3edaa37aee7e71847093179c918",
          "0x22565b5f62e1ae48b8abfa3b66eb71c06c3c850a",
          "0xd3e8d242360a03e935eac0fb7ebd48262e3781f6",
          "0x017347cb75ac8725608e593ea35d83f9b2b3cfb8",
          "0x0fa67a849aac4f65e365783f498ce00a892913fe",
          "0xdcd762aaa2ecf3c972c38d30cbaf7d7b7d647389",
          "0x497452b2b3f5f5f2f4e82b36b67ccdc01964d404",
          "0x5d3087d1aa04235610cad6198598977b9b8156d3",
          "0x6b1edbb38e067897b24082eff2e88fe27c4a100f",
          "0xb7fbb0203a4f05722372b5ec56137356dc3cfeb8",
          "0x8bffc7415b1f8cea3bf9e1f36ebb2ff15d175cf5",
          "0xb49d6cd5edB2f75DCb6E6A4C630680F20Ffdc555",
          "0xf0aa422a657b276bfa5ed750ee843ccf105f8b11",
          "0x68d5120c01269d370e7bfeb43630c4350887be00",
          "0xB08a41a9c67BF9b88C675431885Dd7aA46511B6B",
          "0x8090e50fd814d461cfcc1e2c7435fead21e85895",
          "0xb9556d93ccfa3cc4d66cc9a3d426ecd7a2f21bbb",
          "0x5dea90e69755c4f15221958057deac8432e281e2",
          "0x9e85280cb47ae823ff1d817e8bc969fc08aba6fb",
          "0xc941b6df1cb43025457c7e75d1795bbbac4e9587",
          "0x87d277cf7816781776a0c7da58a67a07840f56bc",
          "0x5157ef1e12a8fe5f7e06a38c6c5192f141598fa5",
          "0xdd41ef8f9ce193abed960ab9d30db0a0dee1dd29",
          "0xa7fb80b759ea191c19720f531398663b298455fd",
          "0x2ffd87baa2f6c7c9888828894e94fdeb3afbe9c8",
          "0x573cd2ed0e42ab76c11f39db3c749cd9dd37745b",
          "0x7a2b399dafcf4a0939b39e3327a305c58d3f0de5",
          "0xC47Ac3dD8b3fCd13C21D567D641A74b7272d5f78",
          "0x6AD5ffcAC8FdB4fa16C646fb51Df778a3e8E2bb2",
          "0x827d12Ee92C61FcC8DE4B90Fd2457264d6E1f36E",
          "0xc7e108db4bebe412e35a7bbc9dd8bac2ba1a27ea",
          "0x1c0079a075d35ef9ad0c20ea6b23d91522c31b09",
          "0xa2f63ebe378a176264e2f8d7ebeff34759bc9f04",
          "0x167336b620e331fd1fb3f1c46fca8afd34824c7d",
          "0xbebb80ef02ce15100e444d5d14bb7335221299a7",
          "0xb91b64fd12c0116f765afc80f3b76c3490bdaa45",
          "0xafD39E68d4b8220efA69D4476d385F1D22494e06",
          "0xaA498af6835558c761afcA4fb600939e4AbAFffD",
          "0x059Aa4A5A5e4393bDE88C758a3A24e79c491Ba3a",
          "0xb3C9754548796Eb3A6f85F90C746C73Ff874840D",
          "0xb25fadf88591e402633ec1af90f1a30c0043f516",
          "0xb6b4d68650255b91303c6c9d307c4921d4444e3a",
          "0x790fD7a5f3cf785D233e38B7e732b3cF5aBc1bA8",
          "0xBeaf3d3699304C6BdF697F9ca2EA3539896e88A9",
          "0x43319D5fF4f3089aAf3fdE3B7d4aD40d6f236bD8",
          "0x82eec5dbd7d2ae462dbefb691bb45a6886207d63",
          "0x89ac7e4b792e7561f421fa302c0fe6cec7321d17",
          "0xBaD9e771F82CA3C8486dcc161d044e75AC949a81",
          "0x268335849210eD7fA42696732838a70024437d0d",
          "0x30a0b4ec17516c4e8ce3e19b9a7279d446f0ee61",
          "0x4d42b6e1f84b449789ced226f36f25e3caf892db",
          "0x2e777a4f557c19a3f2bccf1d033c0cb7ea0722aa",
          "0xB915535d62f7f85097D5E12b43DC9FA4Af374746",
          "0x832d6A2B0931AFCa1213172Fe5daF3b285E00B38",
          "0xc0c5d9671fbd8fa90dad188246a593dd71ebfe42",
          "0x7243213045868C91c09f8336e2405EDbb53206F5",
          "0xaada964ffb80e6de75b5ff188b6b6cd458cec72e",
          "0xdb4782d463628cc5b1de8f1220f755BA3bA4728E",
          "0x9156f20136360aabc3f940af745aa989c0761017",
          "0x6cf8e5ea46cd8af7c2508262caf47e13b68e7758",
          "0xe42758403b8a6c02ff81656856278f74985948cd",
          "0xaf7c2eda62767b3bd2611c6372c9f2fccd7e37a2",
          "0xacf53dd3d1d136c08ce9f3e7debc2e09ea5f4cb0",
          "0x13b8310b5d74b7ce04ab4bf50160180594a8eae0",
          "0xcd3caa346290d01bfe557630cf4e7488da4f8271",
          "0xa36cf3616f20998011a102a394b6b5cf2d28598f",
          "0x77ddadb4c1e47bea01fcbf1179760035d40e8842",
          "0x0b1f712655d6e7ce6aeca291824f44c26bff96d3",
          "0x205410fa2246bdd108a13d385fe83f1dc5133279",
          "0x647d6e16f34433948d165aed4381f1adc962fe11",
          "0xb95a0b2d7772260af5e57de4de1d78b0acfb4167",
          "0x79a6765e192c0e3280d1c15767d98f99fb4a9404",
          "0x8535dc4a06d3b40744e8c495e5c6a29dda489989",
          "0xf98c2cf470f703e9c46d9015bb4b8df17c41faf1",
          "0x06bade3c7893e7172ff65b295d07a0226999c755",
          "0xfcc9ee2ab1291a5635d5eb7fef4ab9eacc20daf3",
          "0x5f31c311a232edbde7ce0c58c51670242d18d153",
          "0x3965b2ed0c859345b1731cf2923a936f2b85bb47",
          "0xddcb8ccd0755ddf2419bfa1b8900452be8500e2f",
          "0x06f4e0d0244dc74319988f5f61665f05eca1b2ba",
          "0xb34e4f360e674d7e66eaa5ce13c09fa392da8c94",
          "0x1e69d34f421d75579aaa9a994f33748f31d64d84",
          "0x157c735f230b4939cb31ffff4eebf8b08135ca66",
          "0x067526baa12f7e65fe4d8080886c8f6078272456",
          "0x1dad55c795aabc7c83949573f82bcbed6226989a",
          "0x23deea90ead9598352b34773a87d42133fe6b7f7",
          "0xc4341519a0aea50cbddf0b06d91bdb166c2264e2",
          "0xef1cd279320302f08deb5cd58a9e5118a74ab101",
          "0x13ffef09c547cc590e23efe6df3397b93952f22d",
          "0x2123e8e2a4579686eaa1346d2c7f21a3cffb7528",
          "0x92dcb42a76ce9709960b5e5e6846f352849783d6",
          "0xbf6cb050941a12305ccadbc594b5fe46290efafb",
          "0xe2a83c5f51928d07679103b508eacf82575e7562",
          "0x661a21806bd30094b2863b79a1ccc70273308438",
          "0xf11bf74e731a2c9a45c39a532a826657681fdf4b",
          "0x398370824c267aeb2b00c40ab193f913ef532ee2",
          "0xfc068d90a5c2e66e14f11b9359eab6cc9e8b45df",
          "0x3012238be3df637a39313d33ad8c58961d89ae7e",
          "0x1e3839b307f2bb3ed5724e40429d981b01345bd1",
          "0x56a061f4ef706e1f6dcdbcf7e10e4340c1d99bbd",
          "0xa262b1ef2cc9b0b266ee319c912850a4ed0d5a83",
          "0xcdcb07b4f1c77fa8960adbccf8a5b523cae435e9",
          "0xe802dc9e3c3a13505565cf7137b510a9da21d4d0",
          "0x161b2bc49f4df4ce9057deb5a78843f4ad8522d4",
          "0x14002e97ad4903b0ead3b1e576a67e2608f5ceed",
          "0xaf5f7615eef4fc5181bc6904a18ba851773b62e2",
          "0xf22b3e9fdfac016fa197c2326c84657ba4f78f78",
          "0x5485ff7438df85120bfaba44e59a55367dd09106",
          "0x74f7065e169cc98d8d877f397cf949132174d1ca",
          "0xc355bb2414889837c85c314e6ff5a4b6fdc7554a",
          "0x332f9651f6dcdb5a015c2a529880b355baf9f93f",
          "0xe4f44bc4520d3161bef03f62967bc361082b3ae0",
          "0x239a2d43c124f51e9bc0cc0581864964fc0154f1",
          "0x6ffcbb08925a2a710505e3ce6aad5392b42626e0",
          "0x59c7f366f5b6b4b1547ab84287d4acdf6b7534db",
          "0x221fce6b6dac61520c1c283825e29bb556979111",
          "0xa37bbccd480c7758b0df9d4d81797e75e63c6b13",
          "0xe110805d72946b533996bce9e39ebff54aa242c7",
          "0xa3b11d1f06d71eaa9cd3d0142f08e7ace9b474ec",
          "0xd45125bd92fa856f54498bb14b6f4addaad0c9c0",
          "0x999eaef521b7425aca4c764cfb0ad0dc6e336e20",
          "0x7f37cb4e1ff71193175b11c16faa7d97aa851876",
          "0x053c01d5252a866bd4a69c99a0c8911c7570ae04",
          "0xba76f21f81030fd6a853450036de90a54aa127b0",
          "0x3ebd637f0fc8f1bac02cbd138916a8e2d7f5898a",
          "0xf67027f464e5f1f5eaacd45e085d34475869a733",
          "0xeaa88efdb766934f138abe9e8db6390bf440dcad",
          "0xea78980594b02f805b607aba747b2b5fb907b708",
          "0xd2ec6fb85782e5c5982743f66d293617803a2e0c",
          "0xfdd9a3e4c07756c1dc31ba938fc062d45eab1668",
          "0x369615bc57975b06c418f42d3fda3754a385b97b",
          "0xc87cc05fe671cd34c78f1f87276cd2f40dfa880b",
          "0xfab22550fcd520a7eced27414cd74bc70a6ac1a9",
          "0x08d1c90a8d2d6ad0e7c8d012187ebedf88e51d25",
          "0x54b065b3a70157f5e120cdb9f0ca8fca8f664e53",
          "0x2e2d9c58996fd62fa30166445fa9376a61bbfcfc",
          "0x5f725f99ebdf84d696a5acd64a2e3894cf407289",
          "0x3c864b2c90bbec9b0f74a190ed3c1f1215b6d81c",
          "0xe957b0829c6ae6ac461d7365b7927e1ff604114b",
          "0xd061a5642a9a9046a774730a2d790cdec698450f",
          "0x2d82c05ff2507525190435bc4538af681f7dbfc2",
          "0x9bd2f98b6dbf08ee09b5beb9d852304d05c3296e",
          "0xfe35e70599578efef562e1f1cdc9ef693b865e9d",
          "0x7ad85243daccfa8c7419e37bcdc9c7a2323c20fa",
          "0xde4b72239f6d6e2342cbc48ca8fb04e05a25f1c7",
          "0x676ccd8fa6c861c88baa1452db9710d5592fd827",
          "0x700eab95ba4aa1e35a98505d41d57ac60aeb49d5",
          "0xf46cf8d8a42c9db6a072545e306c8fcbeffa61f2",
          "0x53d60cc23050616285be006514b47d9d7ad2fa97",
          "0xc9e9e3489d63637c08a34075c1c194f26676e405",
          "0x7450a854ad58c73916e7afd298acca0d71aedcc7",
          "0x53ff05fb1a554759c99a6631ed5801948395d564",
          "0x04028198e451f64212c33ca22cb3b1fba6272459",
          "0x8604a2191f72af1912e8d29885413663dc35ed93",
          "0xd371b7731d19fb656aee6be381ef6c1f543f350d",
          "0x77db2352cac664ee6ca0d6ffa9d8d07d4c1c7fd3",
          "0x4f28e099459f200e2c6cd6cde11d23700c208359",
          "0xce0dacc4e88c14512e56155f8faaeacdaaeb4ea2",
          "0x0b9595bf32ef7fd9ec5cff38ca7049734e32f0e4",
          "0xb2277c6567be71f09aebde976dbe280cf073c8c8",
          "0x696e7128118c9290b4a27f07de2654a8ba3dd1e1",
          "0x317de35c1c462f191eeb5b989b81f5786acf0d03",
          "0x739d74ecc0fa4d75aa9cdf3dab605cd56816cbcf",
          "0x2559af7acccf2009223e324f7c950b44e2d5c6e2",
          "0x970c21da3d93b5e94d04df8a5e024623f91183a9",
          "0xbd14f9461ae00fcdda07f4bf7bc49b0d8a8ec122",
          "0xc760c22fdb451696e109133bfa50c8036a744bd7",
          "0x497fb3e87829dbcb0a9294044fe496a65edce0b7",
          "0x5d7a8711c44086241b55ebe5068358c53df1e02f",
          "0x615e2700a055b4fc5e4853c712b01bef877e6ae0",
          "0x4ffacfd5852ed4b7304004e61291af1578048ae1",
          "0xfdc577a5de3ec68696ec69878d488a16e76d0d3a",
          "0x75720f62a923942adaa36037b57e3a3d38ccb7fb",
          "0x79076943c5cadbc071951386e94e28f4f5611fda",
          "0xf59b2794936d61009a9657f226a9d6541ba2d671",
          "0xa80c9ea231cf721cdb133e135a8a90f07638baf4",
          "0x0f76193227551c5239f3f0c3239228a08ff29453",
          "0xe93f0a95d8b9ce51367467caf71ee81ea8d19978",
          "0x11a92ee380c0ea3d3e195891d627640d4f48b765",
          "0x1994bce3888fb911f06ffb697011b93a95d9cb29",
          "0xbd9544d48ac259733263f0a394f92354ded3f27a",
          "0x56284f2fd2dd095638fc1f8cb23682d4322a3ac1",
          "0xe96db623c2ce30f65171f5fc487127748d1065c6",
          "0x6c5c05300d35ca4c7bbcc8b53227ed8ade03695f",
          "0x0ff0cc6ded3fef4224b2716531ba55df59d96a06",
          "0xcf043e0d1249920ba5356c6c6377f9285a9e0e49",
          "0x6c7935336cca410d15823686688ee3813af31c86",
          "0xa5a1bc1b68facc195a446168d76a9720f64c518a",
          "0x92484a53045f06fe119d2ade6e7d85dd3707ec9d",
          "0x9cca4298406d060747b0702ee36446af7472251d",
          "0x8ace24cb34179f1f860ac0439154dce56488b5d7",
          "0x51684f4ad0937a3b59b19a6284be73af032ec516",
          "0xc0e8cdb6381d2ac831c471fbd5692f0f941529ca",
          "0xb5fd638924ffd3a937f3e9724c46ae505cf784eb",
          "0xc2c6492ae981d06f4c8710e97b1c9440dafbc4a1",
          "0x940ec171090e4920f3adb90d52c66ff1dfbe7037",
          "0x173c46e68187c09e4eb1d223b90a5789f7e6574a",
          "0x4c3bc8e576a1294224039c6e05762e9c9ce3aa40",
          "0xdfb036eb418cc053fe521c57bc47b2463fc3b65a",
          "0xf23deb8a79701bf1e6dc8a7b97f93053eed915d2",
          "0x99652354b07d7a341840c6b5548753ca7d5ba9ad",
          "0xd57122b55c7be43348dcbb79e36240c13190614e",
          "0x644c38c61499e5856d42c975c048e4dc298957f9",
          "0x378dcff8e6be1778b04f5712d686517bb6a01927",
          "0xb36a66271b2c99043fe07c93fc4bd723300d67ae",
          "0xa23fe7b2b0175a5d79b6df51de3a6cc379c6b24d",
          "0x97a95b10a62e8bf27cc29cc6034cec36bc0b9dc6",
          "0x3ebebe0a0cf92c33594f5948e7ea654e6c10ba52",
          "0x54229b20f483803539e17a1334bd335bfc74588f",
          "0x15008a722270900f04dbddaf809ae9cd0af8386c",
          "0xe295492966896909c61597df71d7dece64b9b357",
          "0xc4f814d88bf9a816592dad0a7f6f5ad28b39dfde",
          "0xe1af584a03e12ff30ab9a41405123619988b04a2",
          "0xdceb4aa1c91963d7e91e7ffc9dd6e57d278f00c9",
          "0x6e24ac7a957ba929e48e298c75f6b76d0cdfa901",
          "0x3f4389ebb50c33db3810c573df73fc2182cdd8f6",
          "0x48c5b6c6c4bd4d2c9de13a36d202854d1fe3a1ce",
          "0x07d8305a77c098f4ac5e992e9556f9975d65de63",
          "0xe3a4804d9c7563f00af25b1b17451d9171a75b36",
          "0xf385b9a6d3c622d1890801086de8fa9e694715c0",
          "0xd57077f32368acbc520245164cd9142dd7a14bb8",
          "0xd5623cfe7b351aaa5bdf08adb927bfa9329a449c",
          "0xe5379928a19c6234e2f91dcf01499d3502ff6add",
          "0xb9627002fca1f44ade08bdaf5c6a767a138493de",
          "0x89d73a8f14226bad6bdfff8282f69c2b7816a57b",
          "0xcb54a831f1f2a0fc19842a86df3ceba1be4bc67f",
          "0x1e868e0f5948fc94ed99dde0d0aba939e7677b47",
          "0x2abbbffb4787177021b7a65334a144e13a31a22f",
          "0x97dcb6a62e6d8d02e9b956f7d76eddc02aaf779f",
          "0x7f4b69f6d33852f73ab10c99cecdd0e20b9798b0",
          "0x1ca8ba8a8fbc9df50a5116b7eac81a0c6a5e7d7c",
          "0x4d0e8f3b6e31394ddd52991b765c8e03918bd98b",
          "0x992984C607644B42d4A491f51A0191b0B59F4569",
          "0x3d0696f8cb210e3eb8a9c7bd7edeccf4679f07bd",
          "0x997bebac4194a386247bef93a547e92e53cb708c",
          "0x781bf43f5ca817f4494d9b12cf7bace2bfa100fe",
          "0x4f0c752fdbea79558dda8273750562eed4a518e2",
          "0x2cab4d881962d247218356b32abc4aa5c46ba0d2",
          "0xb0ce32868107ccc5b0b551a3abe4890eed29f869",
          "0x569680033cf81d379e24e0553230ab444fe10559",
          "0x9c7cbc31b3cf99347818425babc843146fb02e69",
          "0xf866b5b53ea94f640b19e7550fe4fd2c18bfaffb",
          "0xe6ceb755e9c5706f001eadebe5e47eaf3bec0e11",
          "0xe8ab537b48ba0e120c8ece3ab068c0e3b5561819",
          "0xad2686165034b4e0e20f2704d55074b1eaafd2e2",
          "0xae150fe9af090eceded52dbadeda6236f3acb865",
          "0x0e5c26c737c708ea3b1c2ab7dedd2e2d52ecc5e5",
          "0xab4c1d180ad252d0af8453a2fbd0b64e03b85f42",
          "0x63c0105a3f67a640197bbd6497d4846c1ca29b5b",
          "0x47c233c3134576c12204e0b8b9e5a10bd7036ca5",
          "0xc73f1368fa0f43561e315875beaa37de519d3cc1",
          "0x9b9ce21a0947c6c7bc71809095f0548a188b65ff",
          "0x81b8a3807b65fca38de8c223c449cb2c324a8549",
          "0x5489a663225fee3aa5bb8426f388bafa3ff3482c",
          "0xf5fc69ecd8ddaaba72b73830b02592200bb78918",
          "0x5b1c563899a4d2038066b6139132abaedf66ac7f",
          "0xdecade78120a1fadac0903deea733192dc6a530e",
          "0x6a4ab14ac091fb7bfe777b98b4eb921d9df9630e",
          "0x6c5491665b5aac18f8e197a26632381af9732028",
          "0xc2d7792ff324b03f7e6e027705305cd2227d4d5e",
          "0x2547703A3f7bf1C2C6dDCA399e3bAFF505fbdfDc",
          "0x5242d5557b19938fa544eed0ca7744d157441517",
          "0xb1010b8fd05373cda5606aaf9f460dd0076e4cc9",
          "0x5e835798876124f5bdea5682a37f15100ee58903",
          "0xd911c34256255ea1df33e4ce2cd207a30f012465",
          "0xeaf962f4e403b174db79570d1dcfa0ac76b7a1bb",
          "0x253a41571830dca332724dff29afb8960ffccdf6",
          "0x3b63294873689f11a7cd0e03d474031bfa81ab32",
          "0xda30c83502fbc38ed13f96560dd4d2e7b355745e",
          "0x7af57a5e18bac6ac1c538449f4f5c88887b16318",
          "0x7388e29da2a3cec20306d0da1f561e6006a7ff3e",
          "0x6e3ebc7786f0b14617bc3cc33a67cf184298879e",
          "0x2013aad56c5c189638868f0ec3350e71d6f33d72",
          "0x7d406e4c934e2cfbf455dac7042530d799a246d2",
          "0x39b210f3e746e40ecc5a672c638f324fd31f6347",
          "0x3fff06ece665ad68969130aecbcbf65690ce1d85",
          "0x0133f65ecb4e7560805457809e36229513151650",
          "0xf0cc6433f0c564a706d834e46ce13dd76e010af8",
          "0x75bfe009e0d9b0afa6c38d0de623efd42fadcd6a",
          "0x9dcaa39a7fb46f6d7281c636253473e43912dd04",
          "0xee915d4ca045af619f253fa969fb670daf7373e1",
          "0x3e1cecdc3d15cb03e91a60470c4453f4fbfc75f9",
          "0x07a8991722a09f7c8869e03a7fa1f5477a797511",
          "0x81D1f53417B9b4828206FCA420bf49b17fE0814D",
          "0xa2342f705047b18c4287c6142bee823b6dfb2287",
          "0xb48393dfc231c96abd4d3e46774dccf79f51f240",
          "0xe6f61afb06caef91a37ba354d65812058f78e6c3",
          "0x7c5419fe3bf6cb62ace0d499782a69cc9cc1f988",
          "0x6d5c59764d1e214773e3f4865ad5f30fe05c0202",
          "0x5b29d2d57bc47a9f55d3889352df04559305bb74",
          "0x426dff8539ece131c6fb97fdf7eb0267dcf025fe",
          "0xc4dad120712a92117cc65d46514be8b49ed846a1",
          "0xeaf7814cdf7236bc5992d19cc455f9c92b00aa9e",
          "0x02feccf6a20b698be2fb98d3a9bc5818c3c67b6c",
          "0xeb4c341489129843b325e8e77e05206fcb9df862",
          "0xf6cfd09752b28b640c6fd520edea09d87a7466c6",
          "0xbd5695b3aa201c442405ce88075cb4640e571756",
          "0xbaaeac4dd925d0ba9cd608dbe3c390e6e4fa2816",
          "0xa620685c041668960b76b93ba995050e8bf69103",
          "0x117972f869f12fd5bd31622eeb12e166acafb6c8",
          "0x8a0cd3cf547622b20e77f39165dfb4ea0d8197a7",
          "0xf6ec5b0d097178a0acf493afdcda2a54545ab0f3",
          "0xc9599928ec82ad8299e70ba0035af73a98c444b3",
          "0xf3b6a3d2fc68491a2822d5835ac3469296ae465e",
          "0xe9448d94f5f7ac4af563cf47eb4a906f11632bc6",
          "0x4077d248d405e29da00c7c0553b2a808676bd70c",
          "0xf0516320eb5005d806992b59c0dab70cf14ca8a1",
          "0x20ea6e57a6c63fdad7a7453677a93728b56ad93e",
          "0x77f3675438e7756215fcca17b0e746eff11f885a",
          "0x33a74ed005785a83295d3ebe7e9fee647c3b4246",
          "0xa33e5e1ccf57c0caf78ae399061f270dd24ffcdf",
          "0x9f85c0a1cfe3af69e3492dc8b4ebc11f79843fee",
          "0x1cf2658119a09b35414714206fc5dd02fc328dcc",
          "0xecf044c08e05c438006bdb92162c718396433cbd",
          "0x6ea45893e1c503b1bd8e7b575d5a1c87a7d5e367",
          "0x5331544239a5ac946b32a217b31c1c439d5304eb",
          "0x93a5225d1070335114ea063b0a04f0f653b71634",
          "0x571b8db726c2a29d237f88c9efeb896290bf395b",
          "0x38b87fa5f96c02db53bb710026d95b5cda20ba70",
          "0xa495fb3ef5ad8323ebe5b369f81785db8236e018",
          "0xb6d19afe6de6c1ab49b964e202ebbf6b8e590a33",
          "0xbb0bbb491d15b27de64f76da8dbbe2e0bebecf8b",
          "0xc7f90cf9033ba51c166002a960bc276274bb7769",
          "0x6d292ceedb53ad7c87809cef8ea8b12f1415c766",
          "0x1f487bfe9367bbe6880bfdf469346d030bf3439f",
          "0x92d859cc56ea22ac01f00eae307f565ca13e54e5",
          "0xf82ca0ef23656120bcd28afd74fb25efebd07e8d",
          "0xcf4b830cce22fd709554ec446a8ed0ae919929ab",
          "0x9ab6d45c2c220e55f7585f268a71982caf775210",
          "0x1a94578c4db2c90042c62fb0311fe1423659475b",
          "0x5fc0be7a7d67a98bea9aad9e4583332e44e19f0f",
          "0x94ef50afac9c04572813f36fb8d676eb400de278",
          "0xe85041b675b616f635798a63db467289e5aa1e4d",
          "0x2882898129bfb577f756350d8443265038fce7cc",
          "0xf256c4c027ce81cd471200a862f11124010e0d1d",
          "0x291c9894f99aa53fc3893fdd8549fbaa300734cf",
          "0x7295c0ed12135083b06c5ff602c73b5aa3f3d33d",
          "0x5cb4c83b0535ba35fe6cb0aae1e878d39efd606d",
          "0xe01d20b1d814e8a58875bf6f3c8952c7c7082307",
          "0x921082f8ec9a43689a1a026de274f71c030a0d05",
          "0x73db4228fdd1d2595f85b23fd63de1abe901e259",
          "0xdae0b666783947cf1918bf06e7bf3d321935a563",
          "0xbac1d3d14bec3bc3eba753555b23448409645f04",
          "0x8ee60a0266dd74821d299390eb99cfe9612d901c",
          "0xde8a37645bc06d318a7120f56e72ad5de877760a",
          "0x3d9ee32b4d8c5f4232b48a4d05e9325b23fe7a61",
          "0x761a3d78ae9da76469857472161273edf40c1a02",
          "0x6abd4757f01d0ada036d639b0661fe13b80ba371",
          "0xfbf4986e4e407db2e5e19a1f311ec9e3e8d99356",
          "0x547aaa1bf305120abb5c08f2697de2a4cbae46b1",
          "0x702094a1ac85a4ae93940f92d672754910310238",
          "0xc5e0e35921ee4f31995347ae2b6df2a825edcda3",
          "0xda85a62e40f1f5ef408c4dabd850944a700173a8",
          "0x173fc24039be80434446d4f7bd67c4fd906ce200",
          "0x5bf8930a345dea4a1bea0ce8b876f7d29cd24787",
          "0x95c91dfd71395f600dc983140b26bc109e5b849b",
          "0xab86b85fc773bca022ea723185ace2ea9f4bdc43",
          "0xf2043513d5bd0def58f9ca635abf1ca8dc68b7ac",
          "0xf4974EbbAe7Ec9DB35d8125b3Ae7F00E42CCa06F",
          "0xc8b6341b7cb07e5d095b055fea8490458dbd1125",
          "0xe57e1caa218f2bac7a27c9fef8cd5a1285552a2e",
          "0x4bdb3562156ea36e0f0789c00dd6372c1c4afd47",
          "0x8ddb73e51d1d05f648383b011510c270c4a8cf90",
          "0x198ac7cbaa92c4ef9b3c4e6343a5317bfc13e88f",
          "0xa197afc2743556d5695069dc288690b8fe6237ab",
          "0x9e01420eed0c8e0bfbe2eca84ee823550851cf0f",
          "0xc56765a9fc8fb1254633e0023b33bdc518894d5f",
          "0x8bee657df79ab411a93bcaecae6019d0ff8a0dd5",
          "0x822cc3a17b77e0a76a85c4e16c2097deaab36330",
          "0xb9a675398559104a48f24c59a250e7ade3ef1160",
          "0xeb463c4ad1f5562b18139dcb4c276eece2fdb559",
          "0x1e5215529c7738f6a0eb6c8d425c2e3f6a077842",
          "0x4d5f10ad4a576a0a5c387c6d77779e3079566f16",
          "0x75e26bb16ae9e3caa68f7bdf731c71b9f42ca373",
          "0x4510a4c5f0992ca38458c83050ad7f1b9a258822",
          "0x92920454be818a31f014e32cadc2674a03f9dce2",
          "0xbcf620ab7e1c047dad9d5c0e9fa7a12d423bd38e",
          "0x60ff0be64ea678545b7a3b35c9cc0b69ef07ee7a",
          "0x353b978378b3ab923AAdD851e0DC9439aB6a1aCA",
          "0x9820c366ad2a0b77bffe9239634e377b871ffbd9",
          "0xb1e6f09073239116ba4c548c552f02ded7a06773",
          "0xE1D5E149B1438276fF8fA5B6Fa94307460669770",
          "0xd551076646ddaeca92810c31bc42da56204310b0",
          "0xf97c0a3dcebb4e970278aab244b0302520441b97",
          "0x6cf2d8b3a02f1ee38b1a06c1bf734a8355957481",
          "0xdc0617d94059f1a6bb2fd1950823eeb198721436",
          "0x80ff7493b17131fe034a59b3b2a76b09de0b9a8d",
          "0x0a0f1ce727b4d34116e025e187fb3dc02c436bbb",
          "0x2e4858061208b9d1c7d13ade40934d9322ba90af",
          "0x456d7079deedcd12cc3b80d324e9f860113f2804",
          "0x2e890283e67b0529829a454fc004b2aa28056600",
          "0x70bc3116844cf1f4f9556133b1967ac1f1731e26",
          "0x0c323ab10f4b0d509e6da2fc91ba6362c63e765e",
          "0xd79586309a9790a0546989624a96439c4be9abd5",
          "0xf33e148881904a8f5bf3a7805d6bea9ea947a4d6",
          "0xe38a41df35d08e34f6668b84268e82d26867bbca",
          "0xdd2ee659cebf16c33d0c66cbf18f2f41859929ee",
          "0x63f7d47fe3ef9d8e0db24e6b16b019259e8f8bc3",
          "0x7b31b83bdac324d9b3004cf976b01c57e1617788",
          "0x034af5d4ef11ec370f205391b9874654eadf6a52",
          "0xdc534b65887d89a9654b7440ab98888da1fea12f",
          "0x95131b9190f129bbe2c268804bf39c43e3711ac2",
          "0x50679f629668449a25b67672bccb7f040f27add4",
          "0xfcf6554b8d106ef103bbf9e7e147e0b44d7c02eb",
          "0x59122b7dd9189dcbf002bb9393cbbc9d362bef76",
          "0x903c58885d18322ff15fcc025174b4dfbf8520cb",
          "0xa45c1d789121e35de9838c8eb075e047dab46914",
          "0xaab2ca8330cf0903bb166fd44fea99bdf607436a",
          "0xb44406147e7b39cbc6f697c6002331c33d334f85",
          "0x84aba93703d0fe2daa6d5dfe4364660e66c45c78",
          "0x5994cda480e64dfb040dfe7c6397f8f643808801",
          "0x409b6803175c65dadfc9c3d389945aa122d04dfe",
          "0xb83daeabc2189d72bbf0e8a8a7dd77676e0051ef",
          "0x527ba3609c49566b33ef53e2f8bcc835a503975a",
          "0x8ee0cf647193edb28205f7e213a50723b73b2efe",
          "0x0a53e127ecb8aa0ec3d1a2543d824c7577be7289",
          "0x5a85fa7e5738baea6b330247c43a2b2e3b93659c",
          "0xf3998eced96ae3cfe880ac9d2637b4118da48e22",
          "0x5a57adbaaac4ee78b624e4d9c063927d9ed60e1a",
          "0x730228dc081e56f3f359dc43333d57203c52cf78",
          "0xa3d0233b9433162ea28e633a3adec194fa5a9f90",
          "0x9ea913f4c2b3b256e0d0175bfe834318cb2d5e1c",
          "0x04add7baa4cf4d9b93ce91585bffec5964868397",
          "0xdd961ce8980e0138b333a2a1d756d546e00f6965",
          "0xb7bd84b71fbe6f2ade449508b2b78cae45a18dc0",
          "0x4cb72456e82aedd8b1ef0f08d03cc6bff96c6291",
          "0xa0bc9f544453061b18202a377854ae28f837cd24",
          "0x69c489719288655dedc602d7fcd345b937176e3d",
          "0xf506423a1d4ac3a7cecd4c290cf94f5a577a9cf0",
          "0xbcc5f241b91420ea8994c3ec16b6a2f5823edc5f",
          "0xead6605b9e105e28bd35e9f494131c10c1281ce9",
          "0xc38acbe20d3b0ee90519204cf679cb479b3eaa8d",
          "0xc45b0f3c51c5a5292c36079b65085a84bc3d8699",
          "0x2e09559640bf0108d2d31d93553606cafae5ed08",
          "0xfb661557605a01af1ce4ef8cfab9e75eea10c7c8",
          "0x331b4a1242ad6a794ef127bd61fe57d7bdfdbb80",
          "0x8a3e1cfd63994381a99fc4d4d5c902495b3fed19",
          "0x71a548c09c6d09df38811c6df025d281e878258b",
          "0xb547ad0cee397084ebd0d579a82b6f743e118e0f",
          "0x7671caf3c8298c5776ca1767c0a3af0b0f79339f",
          "0x4b8a930e59b5151bcf5447df6dfac704f5841272",
          "0x068959bba6641fa13015e649ad4acbce1f80feaa",
          "0x35af58cbf1534d2db6f2e59ba5cc0fb1bcc76d64",
          "0xbdfeb5439f5daecb78a17ff846645a8bdbbf5725",
          "0x55b14da44d6df5e47725fb83875421a5e1dd230c",
          "0x3674251ee3f39f597fa3813c33c35d527d5105d1",
          "0x2917e947fb1925abe184d4062520a11bcf2a5b36",
          "0xb235ba58e93ba482b19e81d66eb001cd6ffd601b",
          "0x1bf2fa5e3c548a44e62653466550c351c361faca",
          "0xc8733e51f30d9cf5f296eb8d8a4e7d643a94a73e",
          "0xc676e1e9978c00becaeefcb2da8fd66f9f8fc5ea",
          "0xd91af5623bd493fad1490e2fa39111b45f6586ce",
          "0x85a8636a9ae9f9851507dcc951b980a038738128",
          "0xf7e744527cc82e24c3badc2082d0391413b321e5",
          "0xd0a35fe2813b7a31ad834b9bcb8482c45886c0f7",
          "0xe4260df86f5261a41d19c2066f1eb2eb4f009e84",
          "0x72caf7c477ccab3f95913b9d8cdf35a1caf25556",
          "0xc81574b9d23138d377347a8d21ffe15232c36ee7",
          "0x3b94d7a8775cf86326d2bd291c7a0c435c10e92b",
          "0xb19f5b919180f259ae30556b84639707fedcec39",
          "0xd874f397202b032e2611cf999ff737da419233e9",
          "0xbbe6c07c5dc0ef4cc5293b2747da2c606f10f46e",
          "0x119ec0ac12f80bfa98c3f211b60483ebc2eb6a10",
          "0x2ad5a6bd95d37b3c82310a97c5c90c5b86c88d6b",
          "0x4821280215dffeef650eaa03762c3d9adeb2afa3",
          "0x3d70c48f39aa687c9878c655a7d5613ceb86ca28",
          "0x13708a7389cfcdf7966babdc4490905306fca5d1",
          "0xb775fe8ace8a483e3a21ac0e5d5c0b8c05b7237b",
          "0xf852544fea875c0bb766c029387395dfbd4a90cc",
          "0x3fc642741574b86a4fa4f6b71b145adb1cd8592a",
          "0xbf3633ed017339328a7c00a54daef8eb306c103e",
          "0x4b6b892b6878e61f421066a01fc03d0648228f82",
          "0x914cd5a1844193534116eeb084ee763fdc645de4",
          "0x828a1b78706c6b12060cb6069aa0bbc9523ad373",
          "0x3956bde249df89bdf9b51d3c202246ef1153a0bc",
          "0xf56f98fe17836340f9dc6970258018ef018656e4",
          "0xf9e30f32ede8db31d1b315090c01d22881c645c1",
          "0x9587a0f264f52d2e2c54f04c15ec950d63862b43",
          "0xdd47d3db30809a107c1dd59417a61230458d278e",
          "0xe10cbd3f396c45c34b88e375fa072ba2d8fb0c7d",
          "0xc456d38afb6a48c15e7eeaac9b32c451846808a3",
          "0x3e3fd41add7de67a12cbf9575d442826b067c71a",
          "0x00b0d7da03fd35504c1ea56c73de8c79c08d21b1",
          "0xbe491f37c23cad9bd8a5be1634d83b00bb4d821c",
          "0xecfcbf3ee1ef25843d646ded02f9b28ea1266e30",
          "0xde4ea497823b8af93ede25bfa1ff6084c2724543",
          "0x0599dbcffb08260d26a8ff5c1f2141f8b410e447",
          "0x4423225b1ec18f4156355425fcf29b1f59caffeb",
          "0x66da05c4372335348378f5fdf45dabf212ddb5be",
          "0x42814cfb08b3a1f9fedd618d35e4e2a156b4488b",
          "0x8d445eee078ff46d7ddc123dac5904fad0acf92e",
          "0xf8d602518747731a4daf67cb95026f2d1b3192a0",
          "0xb5938f76a5850cadeb1f1705ba18a7ae76c897f2",
          "0x9c83ff0f1c8924da96cb2fcb7e093f78eb2e316b",
          "0x5176c758d23aa0693db7b6a936a8ab4024f04239",
          "0x13b46618ce640d79780f3471a77b03478de9501d",
          "0x337ec021ace842e3d2c76931921942d10945d5ba",
          "0x3d823bf187cf873f52fa0bfb94ab4689c44628ac",
          "0x4533d1f65906368ebfd61259daee561df3f3559d",
          "0xb7a8c4acdc82ce2177fb75627f01050428b30f19",
          "0xafaa9247b7b0e246a41b1c26337c5e2fe0d6a598",
          "0xb6ea717c1923aca6d78fe9d4d07ff2478a55a639",
          "0xcffe6ea1635793cddbba135e97a4bc88d323f2b3",
          "0xd3920b500d7df66377967e143867726e2109cd9d",
          "0x6997acbcf220978a1a79402420c80804563849d9",
          "0x528d8d094a5816a29bf99e2acdf50c4b2ce7ce94",
          "0x55810247af2951d40aa9e72dfb1765cb5f2045a3",
          "0x1bafba0ec2bc7cbbe2321d091648734bc7cf7273",
          "0x523b75cae28b057dc7ae8601f42c0ab71bbabf3f",
          "0x04a6b171582bf89567aa91780db5ad2479fc383c",
          "0x81f69a11c79150ccb072865eb7dc54e02a84cd81",
          "0xb6502179bd7fb6791ea7f5fbc89944718039e865",
          "0x1088666760ae73699cd3023b83860b7bea9e7ea7",
          "0x280beb53e450cc18f81c3720fef1a36b6266a8af",
          "0x789963b7b2244758e3ed9e071772d0b04a20c8b2",
          "0xf07aeacf6825f1f0308457b2e0127589f2afef15",
          "0x7879c360614c5666165fdebb559cb92862b30eb6",
          "0x1e082cc52edd1558870f69f46b58d9e09c8b9954",
          "0x102aabc94e5485e42e6cf2838c2230dbee5c1046",
          "0xf63415762ceeebd55adea25d8883b60fc5bc87ca",
          "0xe3c2f717de93f7540fe0dee353aedfa4499e29cb",
          "0xc55c754b9f11198bfcb5b6f1315d47daada0c4ab",
          "0x7681c99d92ca2f87e892f3a2fa90776442fcbf30",
          "0x77458a6af306868a019ace5b18305671b5cd6abe",
          "0xc0fa6e3792d64d2399e71057c1ff36a55372e457",
          "0x7b8a6e7777793614f27799199e32e80f03d18dbe",
          "0x54f5f00c1d8aef29d56db579704ea9c84a351904",
          "0xe707ef3ed9c0284f3443fdee2a4f4bfa53fc8aa2",
          "0x646f2cd15d6f270dcf710f554d826aaaaa068cf5",
          "0xa475a14ddf8bae5465793b5b04f2d0e5b68bcf14",
          "0x792b4ed2b3ddbcef0a3ae09810f3925105a3d6c1",
          "0x4ccdafdadd4a3b2ead1b744c07a9f5ccaca8f771",
          "0xba7933402348a902064499ed883c49843eeb7019",
          "0x439342abf9cb893848a9a5ed5f85e71f44dda9ce",
          "0x3527a1eb3960e83a8598c742acb4b6458cd21ba9",
          "0xf6803179f07aec32ac85c56420554632aafff830",
          "0xaf423481bb944d161ec44e01c8c2015314ba290b",
          "0xaef786b173071c288497ea4ce23bccb51a0c88c9",
          "0x4660fbb1e7c72abddcf4d90b244887e3521ad3b2",
          "0x489d20b135c7e7325f22de88feab1b4b0bd61a8f",
          "0x490262214be7b9486dbfaa547a947ac913889dec",
          "0xa882fb71aa1967a6a8aa11375f8abd5fee5bdd6a",
          "0x8baf524bc7a6430e1cd4c44d9a9bd67bb669f99d",
          "0xf5b407a5bd88f549f6e8fbf4aa13364a7e93581e",
          "0xd8ab9005bc73fd5a163e4f122a6b741a91de206a",
          "0x1ad42fb475192c8c0a2fc7d0df6fac4f71142c58",
          "0xcf95cec457a16c3f2e7c3205dae53526eecab0be",
          "0x4fb5ae963c4fda03d98fc3ff8ff287fdf49de548",
          "0x1a9f2b91b995827a745ddf734f72f0ad5966388a",
          "0xc40d7c9bb1e567a8135c50ea7b8d187c6971bccb",
          "0x10fa8192602ac7707d9e7b0e81b8c721c73e37bf",
          "0x8a6015bc160d6e6524f526f57a5f8ae121a6e7ac",
          "0xd612ed448e90027cc27a7fd70045899c2f7ba395",
          "0xcc902fc6bc83494757b1d75d62d810881045b2e6",
          "0x330AA0a042347313B68Be4CB629323488CF19D20",
          "0x2d3162890e0a81bb0f4a1f65e8878d20853f6216",
          "0xe22eb5fea7d68e3653f8947a2cd471ac7b333ae5",
          "0x6853285716a92af6dd07f2f6aebca23e5b13f8f6",
          "0x975f1be5fbd4ab7a9b77b6dc32fea6f4e26908ad",
          "0xcb85bf5787f7616315ced491d1c6a0589bc09178",
          "0xa899619ed23ab3e50e0c5fa975285e71c7cb52af",
          "0xb1d994ab9c9fa4b156ae5e676579d185c8d08916",
          "0x06bbf21a21eca16eb2a23093cc9c5a75d2dd8648",
          "0x694c99abbc15fba66296d0007c14d00e34baaa2f",
          "0x2b94c14422a0e84ee75de2f255d1478703d15797",
          "0x2b0d3861b312b4d00f2e40d2c3fb1258a23adf25",
          "0x621cba49d5b835f3c5cb5b1c254004565062637c",
          "0xd14d0780e792b1f4637c7c20378f4d51841c7700",
          "0x31f0fb8664002ca2a7745e55e4a40b0f2215a911",
          "0x6f85b8b8fe10157f166ec55dff7ad3edc6d059ec",
          "0x78827e75da8c80689fcd5af11d036cd45e414072",
          "0xa43a8b5ca81ccfede49c7435d2cd89cd12e12845",
          "0x9e86cc88d072e1c0259ee96cfbc457feffcc1fee",
          "0xfade41403a1eee72d805c42f1eaf5f53a54d0e0d",
          "0x43d3028debf620729bdb04030b8596f8ce54ebf2",
          "0x461d47e4375753f6b972f5d92833fe5b5306be9c",
          "0x6a0aa49c26309ab257857ecd65ca364747eee55b",
          "0x30d7e1d8112aee967fb7e52cde53e8c76c8330de",
          "0xd7af5ea14fad145b2d9fd57e321d7bf8301980b5",
          "0xc83efb0611e42e99ede71ebc2c4c2ed621eeedec",
          "0x01c5dfd725a243293e5be5f62a8cdde6490d061a",
          "0xeb9405f3f6b09a2890b7a9d22ec7516803a08bfe",
          "0xc25d57d9a72602e29f397a1d33a47519b4c1941a",
          "0x655151995f6e63cd0ba3d55a7e5b6d5d3049169b",
          "0x1e6a7990f1f82220277a023d71e6348c35f3cef5",
          "0xe9f50e67b58db5b97e7966aba022c5e3a9b03f86",
          "0x6eed6d03b8c675747378822ca60837fe31956316",
          "0x7a7f4487642cb6ba2d09a7f6902eb2fefa2ed5a4",
          "0x541de0606257132e4ca0e34439fcb6675ce2857b",
          "0x47a4ed62364e4716c58fe19bb124a1f7f2dd89d4",
          "0x58f96c1d46db3a967c7dd33f1a3f0b601745d05b",
          "0x6bc7e14cf9fbc5eec0effeb1a4f9fcc7e2457b28",
          "0xbd595d1ba62546e646a292ec60c435e93e7c9ad5",
          "0xcf7f51347e9420268375df12c6bad832df233146",
          "0x58a95b83cbe75d5e5fe53134f141b92ca31eaabd",
          "0xdddd696973fd58db7aec22f96fbdcf40371e9fb1",
          "0x62fd34d0e6e239a4023575b73818ba7cd7b8888a",
          "0xcb501a34883e4320fc410f87f127d373f1a122ac",
          "0xaeedfd2d1d4ed180957e522910ded6513bcaff8f",
          "0x599fb9dafb6224ef4b0123533032722b1ba4a3b9",
          "0x8F9672ADC55F8f5f840E2aeD9338b8E8c11188a9",
          "0xa340df41aa401984859f5c9f5b8b6330e6935d8f",
          "0xe66e39343d48af67fb1679697fca58b08b638459",
          "0xd11dadaba297012098302c3ee045aae80333ffe1",
          "0x90adeaa074b3961a5730b327e2f57ae8fd7865cf",
          "0xe46bfc3ac7743e2d693d60ebad719525634e23e4",
          "0xdc968c118014bc3259296a41da3f90dd88b019aa",
          "0x360a907cd1414d6010696e34bafe97b2e9ad5c27",
          "0xe7d11c8601ecd7cfbf3bd5b0f74157773979ea84",
          "0x87e485cb631d7616256076a9cb64fff5c7baa468",
          "0xa0bdf16f3c91633838ad715a4bc7e8b406093340",
          "0xbcdb110bed266c068b0fbf937e4701b07727a583",
          "0x84dc68ddc5e0cdb79f8ddf4534157ec3a10623c4",
          "0x3ecfba75d5a113241ec899888319a9e1e456ee9a",
          "0xd701246408a7aab1ed17491ea51bb19639084c2a",
          "0x1fc0d73f7edf6b2f9e37d6cf0036bcf7703953ab",
          "0xcb347557278fed92d6b4b1188fb96555a0a7e6b2",
          "0x274f4af54e355fd7dd892b724da177d57b9ef4fa",
          "0x8c59c23a1ab3b07e01eefc03912c041f002b0f17",
          "0xc072b3472eb40a275241245991a2c89f99c8977f",
          "0xb281405429c3bc91e52707a21754cdacecbb035e",
          "0x9050e815c86460252b9a91222cb7c4c39328946d",
          "0x37b97bae047c1f00e22827c31ef62206e4577669",
          "0xc28cbaf3cd5a699fbb39b120c403d4d4951c06f3",
          "0x21e75fffba1a4316cb7cb3978ffcff119fbd6ae5",
          "0x24f712251b2dc907d4a3672c6fa5d992e5c1bd23",
          "0xc52badaa868f070864323ea050a4461f0979c386",
          "0x013285927a5c912a11dffb3254abec1055655614",
          "0x24e717381b90a0eacd9f2cfe9cee20da6fca94a7",
          "0x6786939a63acf04b5ea7ab42178a30e1453f7453",
          "0xbd0b0c5880c126fc31f288f8f8b837a67a45aec2",
          "0x858e678244427f92d69657fd9efaf64866446e1a",
          "0x8d80ac312e93ee470e8c272051baf3d0e6cbc0aa",
          "0x4fceb024bc80041c9d330dc0acb418a992b2ff13",
          "0xb282a6788b19a0aa4823623a5a94a820c7afc392",
          "0xf2e382804e9439ac176773ae942159663594341e",
          "0x95fb1896cb785f9806d4ad592a4b9d587d48a5b9",
          "0x2a087544e306d636b85134352102d6a84fec3445",
          "0x0e88cbaeeae8d34cf6b1f160e27ec01bcbc3c8cd",
          "0xd8b58455fcdecd5af3e14c90c113b8682fe107ff",
          "0x1f65460deedaf45e4c5af8fb54b5913d15322b73",
          "0x42d085c9b2966b57dcab52fba4a6315ab7822e06",
          "0x6920138b0e147c0dda81fa7164e0ea5a28070a37",
          "0x19be73de77eaddafde6a80edb57bb8b7eac2f275",
          "0xdf8fe059b7b9f0fb3165f2dcbd5c410f8b854621",
          "0x4fce560cc4dbbd1f19535247051ba802baf2dc7a",
          "0xd4ef1aa497a13118d3874b65472d3f7f613f0fc9",
          "0x4ba94bfc508b9daab5a26e23e803efe56469407a",
          "0x57c41c5e4e24d1890e6e6bcd07c78f6a0dc61d07",
          "0xff503a65154ff56ce95ac9d3e142802e3dc6b52b",
          "0x43fd488e5bddceb490fca42cf0045a2e5ebc4292",
          "0xf3bbd80d4e4840182e4790e6a45ea21b94752f1c",
          "0xe51ce5a64fe1548c5f8fc8166cede559c67f29f2",
          "0xf195ae9369cae55d585fd839ae9255b11c06b455",
          "0x6b14a26cd04bbf0fa2f074bc7bb4cb1a73c9088d",
          "0x46de1f4ace0d9d45c026e6fd2f70c51e6b74ad03",
          "0x8004f454e1e8c3969e95eaaf007fd391edccb212",
          "0xbe612faacf608e735ec40f059bfb9d921052a095",
          "0xb7832f7da143b43d60dbb89dc427b1314e8d6bfd",
          "0x947245f9057514be448236cde5c20db263bb4f7f",
          "0xfe12478c130d919bc3e66fbab75c5d93c112e926",
          "0xd2e5eb1f372ca01d92963a081a3f445f5131398f",
          "0xb6dc3d02d5e5894fef073811926e71d3aecdac79",
          "0xac2cd09a146943254e8dd385846f4f72c304bbf4",
          "0x83c1baf7de0f9846f0fac84fab2b3242e43c25a7",
          "0x0910be15fc3574ade2072134e54bdcea1072423e",
          "0x14b30b46ec4fa1a993806bd5dda4195c5a82353e",
          "0xc52aafbb11abec474e2766211b8c47986b690c8a",
          "0xab0204e7c2efd576d1fd90c4c1c5b851fc316266",
          "0xf0477c81d749b6bd4b38108e04b4d80f000cac7b",
          "0xc3895890694a943bfbbc860c1dac90f408773996",
          "0x7c45387374deb534a417e3a7399b04f69e43e0b2",
          "0x9305a3a61dc1169845a543d4b4bcf09c0729bc83",
          "0x1689b7aa1cfb6e420756f2f2d575581f7747a5ba",
          "0x1ee4ce5f14d3969a7dd86420dab5d5bced4ad25b",
          "0x086b2c07eb1f497c916af80c14b1d49951eb5a8c",
          "0xb75de461c1c0dc67a4ff1a140752dd2d01246d00",
          "0x4fe674680c32f3963492d5ab38495e9e3e31358b",
          "0x6d5be5052a89d0ae991b90748dac0c18660cfb9a",
          "0xf9df5142559732b35891c1d29ecb17e6f775190e",
          "0xeea182bcb130390ed47b6428da67b92bf30fc9bf",
          "0x8d701bd0504a13aa89bdbd30ad45688d11adeaca",
          "0x7a11f9063317e2f7320818f4dc888a4818eee002",
          "0xe5b9f93517010d41bc474b294899c16cedfa25cc",
          "0x250deebcf6fbfcc94ff6c47ed9d7bb3bae04dbb4",
          "0xb758e98c2c99883353d2ab34c4d7c550e4871aa9",
          "0x1bfc3039cd5c2d310597193ca05df3afe990dfd8",
          "0x01792159bc843bf661b94e2079123b31e193007d",
          "0x72892066205e303c334609253d8e57ab91ef5f82",
          "0x57dff92d95053e7d7147092faca4630fd0e0df3f",
          "0x5876c7d625e0018158cfebc46bc7247843aef1cd",
          "0x1b39aae21e6ecfaef59cc1aaf09025816ded54d8",
          "0xd7efae6e8e0556ebb5e77a499a34fce6a4d8c722",
          "0x8edc4118ea6f9878d637317e49ac7d9668ceed17",
          "0xff2497842cb0e63353563dde7795f463ced150f4",
          "0x4c5ea6cb9104de2c338374114ad7639846ac0d87",
          "0xfd3c7dad0006e053a095813b87164af3908a2e5d",
          "0xa67b03dbb74c8a8dbb8fb93c5d554275817acbf5",
          "0xfa3707af2e616d97714207e98e8207560cd24c54",
          "0xc789b7b6dfb3c72e7c98a2a2892d412a1852fa31",
          "0x2275b8be039e99f88835ce49b16285ad0e61d485",
          "0x3d110a2521e7ff0ee5c7947b0ca4ed77be36557c",
          "0xc6956c6f8d91d0b9f89a6ff6fb80116913d9be4b",
          "0x4756f471c856d0b72c291c9eb46b4a0a33031b00",
          "0x1b148eabce1c9dcfd399df89d0f7f86722ac03ad",
          "0xf1ed57ba44691de512cce1963568823d530c8c6d",
          "0x0e24f8be98b14a20cd122a999c7340601f5535d3",
          "0xfb7dcd1e1c83b1919d97585a048ffffdf942e6bd",
          "0x181954b3a54c3dc13e4ffbd10c0a960f15c25aff",
          "0x9ee370a169196b497e50498cd4ffcbff190d0f9f",
          "0x03199cc92cd5a3b59fff2d4397bd42367ab3b5bf",
          "0x93139823bbab2f766aa1cf26f2c665eec494f7b2",
          "0x918e2ed4e5c83693e89c82ba60920e143a1b32f1",
          "0x3eb2c91cbb6164fd5dfc48ede715f0a3c83a677d",
          "0x70c7214437c999097b91c52b0dea76fc20e640c3",
          "0xc39bf4ac1debdabde447f036247d136059aa4fa5",
          "0x35811d86cfa5f3c8f80b01992681d4683442c3c9",
          "0xbc0b3fccf30de98e88871094af29caeb1e3329f2",
          "0x34ecd8d4bc82105c456584c6c78e7729e5edc879",
          "0xed7920730b3c5aac551801ba72b19f6783a36acb",
          "0xcc39c770c8324aedd68f10b2bfb7076163cdcaf9",
          "0xccca6cf8903b7da7fe19e10972e32dd69c70559c",
          "0x54b644929f5a640d76c6edd3f6c68f2d6f64e965",
          "0xbd9d5275c63918142dc0b4ab0045ad0cbc1d726e",
          "0x9ec60c086ee52803b6689bd59dffe3590c3e82f3",
          "0x4ebea8b86d65819f191348424d4727025feeeccb",
          "0xfd9ae0484d4345eb6b164af5cbd59dd2b252a56d",
          "0xc65c6ace947f8e40afbc9f2e68cc631a1c143e3e",
          "0x5030af6967df26a2bd7788b1d830c952e8ae66e4",
          "0x84c36f536c803b41cb850d8cd1a522c7bb85a900",
          "0x86893c93a26426d9d0f0f3c758b30abce35a2fb7",
          "0xe5c101eab85f612566eabb8b0e36a3746e53cfd3",
          "0x1768ffad305e87d1eb9ef610482e471e70c55e8a",
          "0x0a3f708a6885ea741ee33af8034c85722a0d5c8a",
          "0x60d0cd7b931641d405c88712b87e610fdc47af3f",
          "0x1c31d5bb15e2612dd4f5cef87da416cd6316c0fb",
          "0x6948d7b5980e79fb949c862594a2477ca089974f",
          "0x7afdad9c440441f8e7c679ba325d0c41e6161e72",
          "0x1f2910331d564341ba9a9762f2b7eca182230b2f",
          "0xbfe440c533f6861c00e71ce187f37a1a70b7b7e9",
          "0x05a5787e3bd8478b9ba08a0ef2036296bc0edf87",
          "0xb44dc46454db1115b2f9c8acde69a356e2f18c03",
          "0x306f7e9c8b175b4ab0e776b45ab07d76245f1ddb",
          "0x7f66fcb8aaf2fe05920c52e5e2bfaa74e2869edb",
          "0x189f533cb62077864e0c1000d3bf9fea9870ba5b",
          "0x77d2b4922c017bba0f717b6277adfa54ef02eead",
          "0x6d8188d47b24e6d8061509b7915cc40d31cf4b8c",
          "0xc53b74d59dad5f335a7db7236d77eba1236e9a92",
          "0x74c28b35813be55d8e23957c95778c47bd6d143c",
          "0xde54227dc7cb1de999979f21548096d92b64827f",
          "0xf83b248f203e7c4039e075dd0aecd91f8f45d7f8",
          "0x718d6166707c1880899aa2c924b15abc3bf3f3be",
          "0x0813e1b0a5b1d0d67ceac68696591f5aecc613c7",
          "0x478ed197ed55afcfb7fd143057f64fc87c874b72",
          "0x76ac2b7e0dce2a8d8d2f4a3e22e86a4f3268b79e",
          "0x5a4597d8439bb729acd163c1234a6f2fa75ec547",
          "0x9c77490f0875eec79b215f67bb4cd1f286a034c7",
          "0x2e09847b0539a2c7b5676a0bacd2e749e561e85b",
          "0x4eb166aa490547e12dd3a9eded8d2b5e8e5de0b5",
          "0xe75b5892dab07e35ed2f4d1bba63e31fe28b9f21",
          "0x65824c6e7ce07915d5661bc0625c8a361d525ff6",
          "0x09a3ed4c3b477e53850ede0aac96681ba314193b",
          "0xcf0981e48e2ca8b05566c0f6e48fbd170ce64291",
          "0xa9b4a51085d3330470a479ea220121fce5d78691",
          "0x466358b1af960dbd55fcd9bc647d10a9f11475c6",
          "0x377b59225ef9a706d196b936d6d8b682d24d0cef",
          "0xf9ad71c3715b12f3fc70e8e9f3a105ac2438f3d0",
          "0x3374333a9baf4b188fddf18fc367fd27f390d608",
          "0x37e2ce87e4f9da861bb13810d71438314bdd4978",
          "0xec0bce430678a1dcf8002fea9feb10be4b259c5a",
          "0xcc2f10e87996b856179a2470f9f8176c90297cab",
          "0x33e873a52f74cb4c4cc83ba310b97c3e80d81e81",
          "0x4a0c6d9e95f42901ccc83a309038c327648c2426",
          "0x13434986aa042a4a361a84389fe3c770f15d3a3e",
          "0x63f20d7d378fe34fdd03ca2c09040ca96e36e10b",
          "0xfcfc32333e8e4a97f887489b488a242dfe2adc5e",
          "0x1228a857fd7ee845f4999f33540f6b9d0988e80d",
          "0x7e0f7c822c5de858c8889d0323758cf105bfacf4",
          "0xfd7d7ddb17f200651c4c02c15586c6debd5f6cf5",
          "0xd3d5338526ca1a4ce83ab3e43750e100bfffa698",
          "0x0d9043db45efb536b95fa4495e4afdd14005875c",
          "0x2b4f7fec7d0c9d94993ebb3fedc0f87f0ceb7e5c",
          "0x04597438ae70af15c7d35dbd28ea2b35fe7c47d6",
          "0x2093c9c38b55c232d3d26785736e195e6d4b2668",
          "0xd10ef8798e886e50fb7232d53ccdd734c7970665",
          "0x6b0ea34cc854316dd8784502ff85c1cb24f800b4",
          "0xf02692a0a1c848658f176286a8cbf75010a9090b",
          "0x843a46d1405f22903dd1cd1ad80863dca236bacf",
          "0xc85ee5d609c07b8a8a5e35865da59cd497c7de6a",
          "0xe6b9f6b9cc3dac911976008b131c2ca634d576c5"];
        const leaves = whitelistaddress.map(v => keccak256(v));
        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        const leaf = keccak256(currentAccount);
        const proof = tree.getHexProof(leaf);

        console.log("Initialize payment");
        const gasEstimate = await nftContract.estimateGas.mintWhitelist(proof, { value: ethers.utils.parseEther(mintPrice+"")});
        const safeGasLimit = Math.floor(gasEstimate.toNumber() * 1.5);
        let nftTxn = await nftContract.mintWhitelist(proof, { value: ethers.utils.parseEther(mintPrice+""), gasLimit: safeGasLimit });

        try {
          setMintMessage("Minting... please wait");
          await nftTxn.wait();

          setMintMessage(<span>Minted, see transaction: <a href={`https://etherscan.io/tx/${nftTxn.hash}`} target="_blank">https://etherscan.io/tx/{nftTxn.hash}</a></span>);
        } catch (err) {
          setErrorMessage("Not a whitelist member");
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const mintNftHandler_Public = async () => { 
    try {
      const { ethereum } = window;

      if(ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Initialize payment");
        const gasEstimate = await nftContract.estimateGas.mintPublic({ value: ethers.utils.parseEther(mintPrice+"")});
        const safeGasLimit = Math.floor(gasEstimate.toNumber() * 1.5);
        console.log(safeGasLimit);
        let nftTxn = await nftContract.mintPublic({ value: ethers.utils.parseEther(mintPrice+""), gasLimit: safeGasLimit });

        try {
          setMintMessage("Minting... please wait");
          await nftTxn.wait();

          setMintMessage(<span>Minted, see transaction: <a href={`https://etherscan.io/tx/${nftTxn.hash}`} target="_blank">https://etherscan.io/tx/{nftTxn.hash}</a></span>);
        } catch (err) {
          setErrorMessage("Something has gone wrong, check you have enough ethereum in wallet");
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const isMobile = useMediaQuery("(max-width: 480px)");

  function mintNftsButton_Presale() {
    return (
      <div id="parent">
        <p>
          Welcome to the Cascade Presale Mint! Before you mint, please check the following things: 1. You are on the correct network (Mainnet) 2. You have your metamask extension connected (click on the Connect Wallet button to do so)
        </p>
        <p>
          If you are a founder, mod, or have been told you are receiving a free mint, please click on "Team Mint". If you are on the Access List, please click on "Access List Mint". Finally if you are one of our seven foundation piece holders, please input how many mints you would like to use (maximum is 6!). Note that one of your mints will be free, and that this is the only time you will be allowed to choose!
        </p>
        <p>
          After you click on the correct button, approve the Metamask transaction (if you try to choose an incorrect button note there will be an error or the Metamask transaction will just not be offered!), and then wait for the green success message to pop up, along with the Etherscan link to your mint!
        </p>
        <p style={{textAlign:'center', fontSize: '24px', marginTop:'20px'}}>{`${totalSupply}/1000 minted`}</p>
        <div style={{display:'flex', justifyContent:'center', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'center' : ''}}>
          <div style={{marginLeft: '10px', marginRight:'10px', marginTop: isMobile ? '10px' : ''}}>
            <button onClick={mintNftHandler_Free} className='team-button mint-nft-button'> Team Mint</button>
          </div>
          <div style={{marginLeft: '10px', marginRight:'10px', marginTop: isMobile ? '10px' : ''}}>
            <button onClick={mintNftHandler_Whitelist} className='whitelist-button mint-nft-button'> Access List Mint</button>
          </div>
          <div style={{marginLeft: '10px', marginRight:'10px', marginTop: isMobile ? '10px' : '', display:'flex', flexDirection:'column', alignItems:'center'}}>
            <button onClick={mintNftHandler_Foundation} className='foundation-button mint-nft-button'> Foundation Mint</button>
            <input style={{display:'block', marginTop: '10px', width: "80px", borderRadius: "5px", border: "2px solid black",
            paddingLeft: "5px"}} type='number' min={1} max={6} step={1} value={numberOfMints} onChange={(event)=>setNumberOfMints(event.target.value ? Number(event.target.value) : undefined)} />
          </div>
        </div>
      </div>
    )
  }

  function mintNftButton_Public() {
    return (
      <div id="parent">
        <p>
          Welcome to the Cascade Public Mint! Before you mint, please check the following things: 1. You are on the correct network (Mainnet) 2. You have your metamask extension connected (click on the Connect Wallet button to do so)
        </p>
        <p>
          After you click on the correct button, approve the Metamask transaction, and then wait for the green success message to pop up, along with the Etherscan link to your mint!
        </p>
        <p style={{textAlign:'center', fontSize: '24px', marginTop:'20px'}}>{`${totalSupply}/1000 minted`}</p>
        <div style={{display:'flex', justifyContent:'center'}}>
          <div>
            <button onClick={mintNftHandler_Public} className='cta-button mint-nft-button'> Mint</button>
          </div>
        </div>
      </div>
    )
  }

  const mintOverMessage = () => {
    return (
      <div id="parent">
        <div style={{display:'flex', justifyContent:'center'}}>
          <p style={{textAlign:'center', fontSize: '24px', marginTop:'20px'}}> We are all minted out! A big thank you to everyone who minted and supported our project! You can find our fully minted collection here: <a href={`https://opensea.io/collection/cascade-by-tinkrng-1`} target="_blank">https://opensea.io/collection/cascade-by-tinkrng-1</a></p>
        </div>
      </div>
    )
  }

  const connectWalletButton = () => {
    return (
      <div id="parent">
        <div style={{display:'flex', justifyContent:'center'}}>
          <div>
            <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'> Connect Wallet </button>
          </div>
        </div>
      </div>
    )
  }

  function displayThings() {
    //if(!currentAccount) {
      //return connectWalletButton()
    //}
    //if(contractState == 1) {
    //  if (currentAccount) {
    //    return mintNftsButton_Presale()
    //  }
    //} else if (contractState == 2){
    //  if (currentAccount) {
    //    return mintNftButton_Public()
    //  }
    //}

    return mintOverMessage()
  }

  //useEffect(() => {
  //  checkWalletIsConnected();
  //}, [currentAccount])

  useEffect(() => {
    checkContractState();
  }, [currentAccount])

  //useEffect(() => {
  //  const { ethereum } = window;
  //  if(ethereum) {
  //    const provider = new ethers.providers.Web3Provider(ethereum);
  //    const signer = provider.getSigner();
  //    const nftContract = new ethers.Contract(contractAddress, abi, signer);
  //    const statusInterval = setInterval(async () => {
  //      try {
  //        const tokensMinted = await nftContract.totalSupply();
  //        setTotalSupply(tokensMinted);
  //      } catch (e) {
  //        console.log(e);
  //      }
  //    }, 2000);

  //    return () => {
  //      clearInterval(statusInterval);
  //    };
  //  }
  //}, []);

  return (
    <div className="minting">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="text-center">
            <h1 className="font-weight-light">Minting</h1>
          </div>
          <div>
            {displayThings()}
            <p style={{color: 'green', textAlign: 'center', marginTop: '10px'}}>
              {mintMessage}
            </p>
            <p style={{color: 'red', textAlign: 'center', marginTop: '10px'}}>
              {errorMessage}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Minting;
