import React, { useState } from 'react';
import { useEffect } from 'react';

function Terms() {
	return (
		<div className="home">
	      <div className="container">
	        <div className="row align-items-center my-5">
	          <h1 className="font-weight-light align-items-center">Terms of Use Agreement for Cascade by TinkRNG and Cascade Genesis by TinkRNG</h1>
	          	<div className="text-left">
				<p>This Terms of Use agreement constitutes a legally binding agreement made between the “User” and “TinkRNG” concerning access to, and use of, “Digital Art” produced by TinkRNG as part of Cascade by TinkRNG and Cascade Genesis by TinkRNG.  User agrees that by accessing the website and/or possessing the Digital Art to agree to the terms of the Terms of Use agreement.</p>

				<p>Updates to this Terms of Use agreement or documents posted on this webpage from time to time are hereby expressly incorporated herein by reference. TinkRNG reserves the right to make changes or modifications to these Terms of Use at any time and for any reason.  TinkRNG will alert User about any changes by dating updates (e.g., updating the “Last updated” date of these Terms of Use), and User waives any right to receive specific notice of each such change.</p>

				<p>The Digital Art is generated by an algorithm that makes artistic decisions based on a limited set of freely accessible information about naturally occurring proteins in the Home sapiens proteome.  The information was obtained from The UniProt Consortium and/or The Gene Ontology Consortium. Specifically, only the protein name, amino acid sequence, and two Gene Ontology (GO) annotation terms were used. No additional information was used, and no software or analysis tools from either source were used. TinkRNG believes this use is subject to exceptions under copyright law such as fair use, but to the extent it is considered adapted material, it is licensed under copyright as “Licensed Material” from the following sources:</p>

				<p>UniProtKB” © 2002 – 2022 by The UniProt Consortium, https://www.uniprot.org/, used under Creative Commons Attribution 4.0 International Public License (hereafter “Public License”) (https://creativecommons.org/licenses/by/4.0/legalcode). Complete information on the Public License and Disclaimer of Warranties can be found here: https://www.uniprot.org/help/license. Instructions on citing related publications can be found here: https://www.uniprot.org/help/publications.</p>

				<p>“Gene Ontology” © 1999 – 2022 by The Gene Ontology Consortium, http://geneontology.org/, used under Public License.  Complete information on the license and disclaimer, as well as citing of related publications, can be found here: http://geneontology.org/docs/go-citation-policy/. Note: The Gene Ontology annotation terms correspond to the Gene Ontology project Version 2022–01–13 10.5281/zenodo.5874355.</p>

				<p>Every recipient of the Licensed Material automatically receives an offer from the Licensor to exercise the Licensed Rights under the terms and conditions of the Public License.  Users of the Digital Art agree to the terms of the Public License, which can be accessed at the above websites.</p>

				<p>In particular, in licensing the material from The UniProt Consortium (hereafter “UniProt”) and/or The Gene Ontology Consortium (hereafter “GO”), TinkRNG make the following Disclaimer of Warranties and Limitation of Liability consistent with the Disclaimer of Warranties for UniProt and GO.</p>

				<p>Unless otherwise separately undertaken by TinkRNG, to the extent possible, TinkRNG offers the Digital Art as-is and as-available, and makes no representations or warranties of any kind concerning the Digital Art, whether express, implied, statutory, or other. This includes, without limitation, warranties of title, merchantability, fitness for a particular purpose, non-infringement, absence of latent or other defects, accuracy, or the presence or absence of errors, whether or not known or discoverable. Where disclaimers of warranties are not allowed in full or in part, this disclaimer may not apply to User.</p>

				<p>To the extent possible, in no event will TinkRNG, UniProt, and GO be liable to User on any legal theory (including, without limitation, negligence) or otherwise for any direct, special, indirect, incidental, consequential, punitive, exemplary, or other losses, costs, expenses, or damages arising out of this Public License, use of the Licensed Material, or the Digital Art, even if UniProt, TinkRNG, and/or GO has been advised of the possibility of such losses, costs, expenses, or damages. Where a limitation of liability is not allowed in full or in part, this limitation may not apply to User.</p>

				<p>Additionally, any moral rights, publicity, privacy, and/or other similar personality rights, patent and trademark rights are not licensed under this Public License.  TinkRNG will not indemnify User under any claim arising out of violations of these rights in Licensed Material or the Digital Art, or violations of the Public License.</p>

				<p>The UniProt Consortium and The Gene Ontology Consortium do not endorse or sponsor projects by TinkRNG, including Cascade by TinkRNG or Cascade Genesis by TinkRNG.  TinkRNG is not granted any official status by The UniProt Consortium and The Gene Ontology Consortium. Furthermore, Digital Art from TinkRNG is intended solely to be artwork. TinkRNG does not modify or distribute any scientific data, and Cascade by TinkRNG and Cascade Genesis by TinkRNG are not intended for any scientific or other use. Ownership of Digital Art from TinkRNG does not include any of the biological information associated with artistic decisions used to create the art.</p>

				<p> 1. TinkRNG cites the following publication for UniProtKB: The UniProt Consortium. UniProt: the universal protein knowledgebase in 2021.
				2.  TinkRNG cites the following publications for Gene Ontology: Ashburner et al. Gene ontology: tool for the unification of biology. Nat Genet. May 2000;25(1):25–9. The Gene Ontology resource: enriching a GOld mine. Nucleic Acids Res. Jan 2021;49(D1):D325-D334.</p>
	          </div>
	        </div>
	      </div>
	    </div>
	)
}

export default Terms;
