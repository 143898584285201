import React from "react";
import twitterlogo from '../images/twitterlogo.png'
import discordlogo from '../images/discordlogo.png'
import foundationapplogo from '../images/foundationapplogo.jpeg'
import mediumlogo from '../images/mediumlogo.png'

function Footer() {
  return (
    <div className="footer">
      <footer className="py-3 bg-light fixed-bottom">
        <div className="container">
          <p className="text-center">
            <a href="https://twitter.com/tinkrng">
              <img src={twitterlogo} width="40" height="20" text-align="center"></img>
            </a>
            <a href="https://discord.com/invite/tinkRNG">
              <img src={discordlogo} width="40" height="20" text-align="center"></img>
            </a>
            <a href="https://foundation.app/@tinkRNG">
              <img src={foundationapplogo} width="30" height="20" text-align="center"></img>
            </a>
            <a href="https://medium.com/@tinkrng">
              <img src={mediumlogo} width="100" height="30" text-align="center"></img>
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
